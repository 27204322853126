
import './modals.scss';

const InstructionsModal = ({ modalButtonFunc, logo, darkLogo, mode, content }) => {

    return(
        <div className={"modal instructions-modal flex-column " + mode}>
            <div className='modal-border flex-row'>
                <div className='modal-border-section' style={{backgroundColor: "#ff002f"}}></div>
                <div className='modal-border-section' style={{backgroundColor: "#ffb300"}}></div>
                <div className='modal-border-section' style={{backgroundColor: "#fcff63"}}></div>
                <div className='modal-border-section' style={{backgroundColor: "#70ff63"}}></div>
                <div className='modal-border-section' style={{backgroundColor: "#63fff2"}}></div>
                <div className='modal-border-section' style={{backgroundColor: "#85d2ff"}}></div>
                <div className='modal-border-section' style={{backgroundColor: "#ff9cb4"}}></div>
            </div>
            <img alt="logo" className={"logo light_logo "} src={logo} />
            <img alt="logo" className={"logo dark_logo "} src={darkLogo} />
            <p className="title black_text">How to play:</p>
            <hr/>
            <div className='content'>
                {content}
            </div>
            <hr/>
            <div className='modal-buttons flex-row' >
                <button className='modal-button standard-button' onClick={() =>{modalButtonFunc()}}>Let's Play!</button>
            </div>
            <div className='modal-border bottom-border flex-row'> <div className='modal-border-section' style={{backgroundColor: "#ff002f"}}></div>
                <div className='modal-border-section' style={{backgroundColor: "#ffb300"}}></div>
                <div className='modal-border-section' style={{backgroundColor: "#fcff63"}}></div>
                <div className='modal-border-section' style={{backgroundColor: "#70ff63"}}></div>
                <div className='modal-border-section' style={{backgroundColor: "#63fff2"}}></div>
                <div className='modal-border-section' style={{backgroundColor: "#85d2ff"}}></div>
                <div className='modal-border-section' style={{backgroundColor: "#ff9cb4"}}></div></div>
        </div>
    )
}

export default InstructionsModal