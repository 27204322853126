
import { shuffleArray } from '../GlobalFunctions/globalFunctions';
import './modals.scss';
import React, { useState, Fragment } from 'react';

const PlayersModal = ({ modalButtonFunc, logo, darkLogo, mode, content }) => {

    const color_mode = mode;

    const colors = ["#ff002f", "#ffb300", "#ffea00", "#70ff63", "#63fff2", "#85d2ff", "#ff9cb4","#bc5eff"]
    const starting_colors = ["#ff002f", "#ffb300", "#70ff63", "#63fff2", "#85d2ff", "#ff9cb4"]
    const fates = [
        "ended up in a civil union threesome between a 50 year old pig bottom and an 18 year old dominatrix.",
        "tried to deepthroat a 12 inch cock and ruptured their airways. Now they use a smoker voicebox to talk at anti-drug assemblies, pretending to have gotten lung cancer.",
        "married a drug lord, and then when the republicans outlawed PrEP they converted his business into a PrEP smuggling ring, selling it on the streets.",
        "slipped and hit their head on the concrete, getting gaymnesia and accidentally turned straight! They now have 3 kids in the suburbs.",
        "got his by a lexus, then ended up marrying it's owner as their new sugar daddy.",
        "put themselves into cryo sleep only to wake up in 1000 years where everyone evolved to have 16 foot penises.",
        "lost their job and is now a sign flipping dancing penis for the local sex shop.",
        "slept their way to the top of the corporate ladder, bought PornHub and then deleted all the straight porn from it.",
        "became a stripper, eating McDonalds every day until their ass was so massive that when they drop it low an earthquake happens.",
        "got arrested for blowing someone in public. Became a prison pass around bottom and continued commiting crimes upon release to be sent back to the orgy pits of prison.",
        "learned ventriloquism with their genitals and now has a world famous residency in Vegas.",
        "underwent a serious of plastic surgeries to have functional penises installed all over their body. They currently have 37.",
        "went rogue and licked a vagina one time, and accidentally inhaled an egg and impregnated themselves, giving birth through their ass.",
        "started a revolution, overtook the government, and passed a law saying all big cocked men have to be nude 24/7",
        "became a scientist and discovered a way to turn fat into cum. They sold their formula and are now a millionaire while all gay men are insanely toned.",
        "astral projected themselves into becoming a sperm, experiencing every creampie that ever happened all at once.",
        "started a fake religion as a joke, but now accidentally have a million loyal followers.",
        "opened a nude beach with the worls first hotness detectors to accurately divide people into leagues. Everyone on the beach gets to fuck.",
        "has a son and disowns them for being straight, before finally coming around and accepting him and his girlfriend back into their house.",
        "studied with their life partner to make a way bottoms could become pregnant. Condom sales skyrocketed.",
        "got every STD known to man, but somehow they balanced each other out, and their blood cells now cure all diseases, but they're afraid of needles so no one gets any blood from them.",
        "went on a secret mission to fuck and expose all the homophobic senators, but ended up falling in love. Now all of them live together in the white house as one big polyamorous couple.",
        "fucked Donald Trump for the money... still regrets it.",
    ]
    
    const placeholders = [
        "Dog Dick Red",
        "Orange Flavored Lube",
        "Piss Kink Yellow",
        "Green Glizzy Gobbler",
        "Baby Blue Briefs",
        "Blue Ballin Boys",
        "One in the Pink",
        "Purple Nurple Clamps"
    ]

    const shuffled_fates = shuffleArray(fates)

    const [playerCount, setPlayerCount] = useState(0)
    const [chosenColors, setChosenColors] = useState([])
    const [modalPlayers, setModalPlayers] = useState([])

    const setupPlayerCount = (count) => {
        setPlayerCount(count);
        let modalPlayersObj = []
        modalPlayersObj = [];
        let colorsObj = [];
        for (let i = 0; i < count; i++) {
            modalPlayersObj.push({
                player_id: i,
                player_name: "",
                player_points: 0,
                player_color: starting_colors[i],
                player_fate: shuffled_fates[i],
            })
            colorsObj.push(starting_colors[i])
        }
        setModalPlayers(modalPlayersObj)
        setChosenColors(colorsObj)
    }

    const checkPlayers = () => {
        let can_play = true;
        if (modalPlayers.length < 2) {
            can_play = false;
        }
        modalPlayers.forEach(element => {
            if (element.player_name === "" || element.player_name === null || element.player_name === undefined) {
                element.player_name = placeholders[colors.indexOf(element.player_color)]
            }
        });
        return can_play
    }

    const changePlayerName = (i) => {
        let input = document.getElementById("player_name_" + i)
        let copy = [...modalPlayers]
        copy[i].player_name = input.value
        setModalPlayers(copy)
    }

    const savePlayers = () => {
        if (checkPlayers()) {
            modalButtonFunc(modalPlayers)
        }
    }

    const changeColor = (i, color) => {
        let colors = [];
        let copy = [...modalPlayers]
        copy.forEach(element => {
            colors.push(element.player_color)
        });
        if(!colors.includes(color)){
            copy[i].player_color = color
            setModalPlayers(copy)
            let new_colors = []
            modalPlayers.forEach(element => {
                new_colors.push(element.player_color)
            });
            setChosenColors(new_colors)
        }
    }

    return (
        <div className={"modal players_modal flex-column " + mode}>
            <div className='modal-border flex-row'>
                <div className='modal-border-section' style={{ backgroundColor: "#ff002f" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#ffb300" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#fcff63" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#70ff63" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#63fff2" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#85d2ff" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#ff9cb4" }}></div>
            </div>
            <div className='modal_content-scroll flex-column'>
            <div className='modal_content'>
            <div className='flex-column'>
            <img alt="logo" className={"logo light_logo "} src={logo} />
            <img alt="logo" className={"logo dark_logo "} src={darkLogo} />

            <p className="title black_text">Player Setup</p>
            <div className='content'>
                {content}
            </div>
            <p className="header black_text">How Many Players?</p>
            <div className="players-row fill_parent flex-row">
                <div style={{ color: "#ff002f", textShadow: "2px 2px 5px #ff8a8a" }} className={"player_number_button clickable " + `${playerCount !== 2 && playerCount !== 0 ? "unselected" : ""} ${playerCount === 2 ? "selected" : ""}`} onClick={() => { setupPlayerCount(2) }}>2</div>
                <div style={{ color: "#ffb300", textShadow: "2px 2px 5px #ffd88a" }} className={"player_number_button clickable " + `${playerCount !== 3 && playerCount !== 0 ? "unselected" : ""} ${playerCount === 3 ? "selected" : ""}`} onClick={() => { setupPlayerCount(3) }}>3</div>
                <div style={{ color: "#70ff63", textShadow: "2px 2px 5px #99ff8a" }} className={"player_number_button clickable " + `${playerCount !== 4 && playerCount !== 0 ? "unselected" : ""} ${playerCount === 4 ? "selected" : ""}`} onClick={() => { setupPlayerCount(4) }}>4</div>
                <div style={{ color: "#85d2ff", textShadow: "2px 2px 5px #8af7ff" }} className={"player_number_button clickable " + `${playerCount !== 5 && playerCount !== 0 ? "unselected" : ""} ${playerCount === 5 ? "selected" : ""}`} onClick={() => { setupPlayerCount(5) }}>5</div>
                <div style={{ color: "#ff9cb4", textShadow: "2px 2px 5px #ff9cc0" }} className={"player_number_button clickable " + `${playerCount !== 6 && playerCount !== 0 ? "unselected" : ""} ${playerCount === 6 ? "selected" : ""}`} onClick={() => { setupPlayerCount(6) }}>6</div>
            </div>

            {playerCount >= 2 && (
                <div className='player_inputs_container'>
                    <hr />
                    <div className='name_inputs'>
                        {modalPlayers.map((player, i) =>
                            <Fragment key={"grind_episode_" + i}>
                                <div className='player_name_div'>
                                    <input autoComplete="off" id={"player_name_" + i} placeHolder={`${placeholders[colors.indexOf(player.player_color)]}`} className="standard-input" style={{ color: player.player_color, borderColor: player.player_color }} type="text" maxLength={20} onBlur={() => { changePlayerName(i) }} />
                                    {playerCount < colors.length && (
                                        <div className='player_colors_row flex-row'>
                                            {colors.map((color, index) =>
                                                <div className={`player-modal-color ${player.player_color ===color ? "active_color" : chosenColors.includes(color) ? "inactive_color" : ""}`} style={{ backgroundColor: color }} onClick={() => {changeColor(i, color)}}></div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </Fragment>
                        )}
                    </div>
                    <hr />
                </div>
            )}

            <div className='modal-buttons flex-row' >
                <button id="play_button" className={`${playerCount < 2 ? "hidden " : " "} modal-button standard-button`} onClick={() => { savePlayers() }}>Let's Play!</button>
            </div>
            </div>
            </div>
            </div>
            <div className='modal-border bottom-border flex-row'> <div className='modal-border-section' style={{ backgroundColor: "#ff002f" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#ffb300" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#fcff63" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#70ff63" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#63fff2" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#85d2ff" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#ff9cb4" }}></div></div>
        </div>
    )
}

export default PlayersModal