export function setToken(val) {
    return localStorage.setItem("jwt", val)
}

export function getToken(){
    return localStorage.getItem("jwt")
}

export function removeToken() {
    return localStorage.removeItem("jwt")
}

export function checkToken(){
    var token = localStorage.getItem("jwt");
    if(token === "" || token === null){
        return false
    }else{
        return true
    }
}