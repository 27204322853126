
import { shuffleArray } from '../GlobalFunctions/globalFunctions';
import './modals.scss';
import React, { useState, Fragment } from 'react';

const PlayersModal = ({ modalButtonFunc, logo, darkLogo, mode, content }) => {



    const [gameMode, setGameMode] = useState("")
    const [gameLevel, setGameLevel] = useState("")

    return (
        <div className={"modal settings_modal flex-column " + mode}>
            <div className='modal-border flex-row'>
                <div className='modal-border-section' style={{ backgroundColor: "#ff002f" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#ffb300" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#fcff63" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#70ff63" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#63fff2" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#85d2ff" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#ff9cb4" }}></div>
            </div>
            <div className='modal_content-scroll flex-column'>
                <div className='modal_content'>
                    <div className='flex-column'>
                        <img alt="logo" className={"logo light_logo "} src={logo} />
                        <img alt="logo" className={"logo dark_logo "} src={darkLogo} />

                        <p className="title black_text">Setup Game</p>
                        <div className='content'>
                            {content}
                        </div>
                        <p className="header black_text">Game Mode:</p>
                        <div className="players-row fill_parent flex-row">
                            <div className={"setting_image_button clickable " + `${gameMode !== "classic" && gameMode !== "" ? "unselected" : ""} ${gameMode === "classic" ? "selected" : ""}`} onClick={() => { setGameMode("classic") }}>
                                <img alt="classic mode" src="/images/dare/chip-classic.png" />
                            </div>
                            <div className={"setting_image_button clickable " + `${gameMode !== "revenge" && gameMode !== "" ? "unselected" : ""} ${gameMode === "revenge" ? "selected" : ""}`} onClick={() => { setGameMode("revenge") }}>
                                <img alt="revenge mode" src="/images/dare/chip-revenge.png" />
                            </div>
                        </div>
                        <p className="header black_text">Game Level:</p>
                        <div className="players-row fill_parent flex-row">
                            <div className={"setting_image_button clickable " + `${gameLevel !== "mild" && gameLevel !== "" ? "unselected" : ""} ${gameLevel === "mild" ? "selected" : ""}`} onClick={() => { setGameLevel("mild") }}>
                                <img alt="classic mode" src="/images/dare/chip-mild.png" />
                            </div>
                            <div className={"setting_image_button clickable " + `${gameLevel !== "medium" && gameLevel !== "" ? "unselected" : ""} ${gameLevel === "medium" ? "selected" : ""}`} onClick={() => { setGameLevel("medium") }}>
                                <img alt="revenge mode" src="/images/dare/chip-medium.png" />
                            </div>
                            <div className={"setting_image_button clickable " + `${gameLevel !== "spicy" && gameLevel !== "" ? "unselected" : ""} ${gameLevel === "spicy" ? "selected" : ""}`} onClick={() => { setGameLevel("spicy") }}>
                                <img alt="revenge mode" src="/images/dare/chip-spicy.png" />
                            </div>
                        </div>
                        <div className='modal-buttons flex-row' >
                            <button id="play_button" className={`${gameMode == "" || gameLevel == "" ? "hidden " : " "} modal-button standard-button`} onClick={() => { modalButtonFunc(gameMode, gameLevel) }}>Let's Play!</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal-border bottom-border flex-row'> <div className='modal-border-section' style={{ backgroundColor: "#ff002f" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#ffb300" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#fcff63" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#70ff63" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#63fff2" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#85d2ff" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#ff9cb4" }}></div></div>
        </div>
    )
}

export default PlayersModal