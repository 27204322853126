import React, { Fragment, useEffect, useState } from 'react';
import './grind.scss';
import { getGameID, getRandomInt, shuffleArray, sortArrayDesc, getBackendUrl, getAnswerUrl } from '../../../components/GlobalFunctions/globalFunctions';
import InstructionsModal from '../../../components/Modals/instructions-modal';
import PlayersModal from '../../../components/Modals/grind-players-modal';
import HamburgerMenu from '../../../components/Nav/hamburger-menu';
import { useNavigate } from 'react-router-dom';

const Grind = ({ mode, setMode, setModalContent, setShowModal, showModal }) => {

    const [gameLoaded, setGameLoaded] = useState(false)
    const [grid_one, setGridOne] = useState([])
    const [grid_two, setGridTwo] = useState([])
    const [current_grid, setCurrentGrid] = useState(1)
    const [players, setPlayers] = useState([])

    useEffect(() => {
        getGame()
        setModalContent(
            <InstructionsModal
                modalButtonFunc={showPlayerModal}
                logo={"/images/grind/grind-logo.png"}
                darkLogo={"/images/grind/grind-logo-dark.png"}
                mode={mode}
                content={
                    <div className='black_text instructions_content'>
                        <div className='section flex-column'>
                            <p className='words black_text'>This game has two rounds. Both rounds are identical, except the second round is worth double the points!</p>
                        </div>
                        <div className='section flex-column'>
                            <p className='header'>Setting Up</p>
                            <p className='words'>First things first we need to know what to call you! Think of your team names, then have the game master enter them on the player creation screen</p>
                            <p className='words'>While the players think of their names, the gamemaster should log into the gamecenter on their mobile device <a className="clickable" href={getAnswerUrl()}>(click here)</a> and pull up the answers for the game.</p>
                        </div>
                        <div className='section flex-column'>
                            <p className='header'>The Grid</p>
                            <p className='words'>The grid is divided into columns. Above each column is the category. Then underneath are 5 profiles, with point value increasing from top to bottom.</p>
                        </div>
                        <div className='section flex-column'>
                            <p className='header'>Gameplay</p>
                            <p className='words'>The game master picks whoever dressed the most sexy to start. They get to choose a profile from the grid. The game master will click on the profile and the question will be brought up.</p>
                            <p className='words'>Using whatever method you want (buzzers, raising a shoe, mooning the game master, grabbing the lubed up dildo, etc) determine who answers first. They have the chance to answer the question.
                                <br></br><br></br><span style={{ color: "#2dd100" }}>If they are correct: </span>the game master will press the button that corresponds to them at the bottom of the question screen, and they will be awarded the points!
                                <br></br><br></br> <span style={{ color: "#ff002f" }}>If they are incorrect: </span> the other players will have a chance to answer. But each player only gets <b>ONE GUESS</b>. If everyone has guessed and no one gets the points, then the question is forfeited.
                                The game master will press the forfeit button at the bottom of the question screen, and the question will be hidden.  </p>
                            <p className='words'>If someone got the question correct they will get to pick the next profile question. If no one got it correct whoever is in last place gets to choose the next question.</p>
                            <p className='words'>After the grid is empty the game master can press the second round button and the new grid will appear. Proceed like before until all questions are gone. <br></br><br></br>Then whoever has the most points wins!</p>
                        </div>
                        <div className='section flex-column'>
                            <p className='header'>Sugar Daddies</p>
                            <p className='words'>1-3 profiles are secret <b>Sugar Daddies!</b> These profiles will award double points.</p>
                        </div>
                    </div>
                } />
        )
        setShowModal(true)
        getGame()
    }, []);


    const showPlayerModal = () => {
        setShowModal(false)
        setModalContent(<PlayersModal
            modalButtonFunc={setupPlayers}
            logo={"/images/grind/grind-logo.png"}
            darkLogo={"/images/grind/grind-logo-dark.png"}
            mode={mode} />)
        setShowModal(true)
    }

    const setupPlayers = (player_array) => {
        setPlayers(player_array)
        setShowModal(false)
    }

    const getGame = () => {

        let profiles = [
            "abs_1.png",
            "abs_2.png",
            "abs_3.png",
            "abs_4.png",
            "abs_5.png",
            "abs_6.png",
            "abs_7.png",
            "abs_8.png",
            "abs_9.png",
            "abs_10.png",
            "blank_profile.png",
            "blank_profile.png",
            "blank_profile.png",
            "blank_profile.png",
            "blank_profile.png",
            "blank_profile.png",
            "blank_profile.png",
            "blank_profile.png",
            "blank_profile.png",
            "blank_profile.png",
            "blank_profile.png",
            "blank_profile.png",
            "blank_profile.png",
            "blank_profile.png",
            "blank_profile.png",
            "blank_profile.png",
            "blank_profile.png",
            "blank_profile.png",
            "blank_profile.png",
            "blank_profile.png",
            "blank_profile.png",
            "blank_profile.png",
            "blank_profile.png",
            "blank_profile.png",
            "blank_profile.png",
            "blank_profile.png",
            "blank_profile.png",
            "blank_profile.png",
            "blank_profile.png",
            "blank_profile.png",
            "blank_profile.png",
            "blank_profile.png",
            "blank_profile.png",
            "blank_profile.png",
            "blank_profile.png",
            "blank_profile.png",
            "blank_profile.png",
            "blank_profile.png",
            "blank_profile.png",
            "blank_profile.png",
        ]

        let shuffled_profiles = shuffleArray(profiles)

        let names = [
            "Brojob",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "22",
            "Total Top",
            "3 in, Cut",
            "Nasty Pig Bottom",
            "DomDaddy69",
            "Kyle",
            "Str8",
            "dL",
            "dl",
            "DL",
            "Sucking Now",
            "Buttlicker",
            "",
            "Fist Me",
            "",
            "Rn",
            "",
            "Gaping Hole",
            "",
            "Gerald",
            "",
            "18 Twink",
            "",
            "Tie Me Up",
            "",
            "Water Sports?",
            "",
            "",
            "Couple",
            "",
            "",
            "Masc4Masc",
            "",
            "",
            "Big Spoon",
            "",
            "",
            "Fem Only",
            "Now",
            "",
            "45",
            "",
            "Looking Rn",
            "J",
            "",
            "Jake",
            "Twink Whore",
            "",
            "TwinkTop",
            "",
            "",
            "Visitor",
            "S&M",
            "",
            "Verse Bottom",
            "",
            "hmu",
            "",
            "Switch",
            "",
            "Jock",
            "Kinky",
            "rn",
            "Bi",
            "18",
            "",
            "Hole",
            "Size Queen",
            "",
            "22",
            "m@ss@ge",
            "visiting",
            "Fit Only",
            "",
            "",
            "horny",

        ]

        let shuffled_names = shuffleArray(names)


        let url = getBackendUrl() + '/api/grind/' + getGameID()

        console.log(url)

        fetch(url)
        .then(response => response.json())
        .then((jsonData) => {
            // jsonData is parsed json object received from url
            console.log(jsonData)

            let first_grid = jsonData[0]
            let second_grid = jsonData[1]

            let sugar_daddies = [getRandomInt(50), getRandomInt(50), getRandomInt(25)]
            getNewQuestions(first_grid, 0, setGridOne, shuffled_profiles, shuffled_names,sugar_daddies)
            getNewQuestions(second_grid, first_grid.length * 5, setGridTwo, shuffled_profiles, shuffled_names, sugar_daddies)
            setGameLoaded(true);

        })
        .catch((error) => {
            // handle your errors here

            console.error(error)
        })
    }

    const checkForWinner = () => {
        let squares = document.getElementsByClassName("grid_cell");
        let is_done = true;
        if (current_grid === 2) {
            let i = 0;
            while (i < squares.length && is_done) {
                if (!squares[i].classList.contains("selected")) {
                    is_done = false;
                }
                console.log(i + " is " + is_done)
                i++;
            }

            if (is_done) {
                showWinnersScreen()
            }
        }
    }

    const showWinnersScreen = () => {

        let sorted = sortArrayDesc(players, "player_points")

        setModalContent(<WinnersModal
            setShowModal={setShowModal}
            players={sorted} />)
        setShowModal(true)
    }

    const givePoints = (index, points) => {
        if (index <= players.length) {
            let new_points = parseInt(players[index].player_points) + parseInt(points)
            let copy = [...players]
            copy[index].player_points = new_points
            setPlayers(sortArrayDesc(copy))
        }
        setModalContent()
        setShowModal(false)
        checkForWinner()
    }

    const showQuestion = (question, id) => {
        document.getElementById(id).classList.add("selected")
        setModalContent(
            <QuestionModal
                question={question}
                players={players}
                givePoints={givePoints}
                mode={mode}
            />)
        setShowModal(true)
    }

    const getNewQuestions = (questions, j, setGrid, profiles, names, sugar_daddies) => {
        let new_grid = [];
        let i = j;
        questions.forEach(cat => {
            let new_column = [];
            cat.questions.forEach(element => {
                var new_element = element
                new_element.points = element.question_points + getRandomInt(50);
                new_element.image = profiles[j]
                new_element.name = names[j]
                new_element.online = getRandomInt(5) === 4
                if (sugar_daddies.includes(j)) {
                    new_element.double = true
                    new_element.points = (new_element.points * 2)
                } else {
                    new_element.double = false
                }
                new_column.push(new_element)
                j++
            });
            new_grid.push({
                "category_name": cat.category_title,
                "questions": new_column
            })
        });

        setGrid(new_grid)
    }

    return (
        <div className={"grind-page page " + mode}>"
            <img alt="background" className={"page-background-image"} src={`/images/grind/${current_grid === 1 ? "back" : "back2"}.jpg`} />
            <div className={"flex-row " + mode + `${players.length < 2 ? " hidden" : ""}`}>
                <div className='hamburger-menu-container'>
                    <HamburgerMenu mode={mode} setMode={setMode} isGames={false} />
                </div>
                <div className='controls'>

                </div>
                <div className='grids flex-row'>
                    <div className="grid_tabs flex-column">
                        <div className={`grid_tab first_tab clickable ${current_grid === 1 ? "active_tab" : "inactive_tab"}`} onClick={() => { setCurrentGrid(1) }}>Nearby</div>
                        <div className={`grid_tab second_tab clickable ${current_grid === 2 ? "active_tab" : "inactive_tab"}`} onClick={() => { setCurrentGrid(2) }}>Explore</div>
                    </div>
                    {current_grid === 1 && (
                        <div id="first_grid" className='grid flex-row'>
                            {grid_one.map((column, i) =>
                                <Fragment key={'grid_one_column_' + i}>
                                    <div className="grid_column flex-column">
                                        <div className='column_title flex-row' draggable="false" ><p>{column.category_name}</p></div>
                                        <div className='column_questions'>
                                            {column.questions.map((question, index) =>
                                                <Fragment key={'grid_one_column_' + i + "_cell_" + index}>
                                                    <div id={'grid_one_column_' + i + "_cell_" + index} className="grid_cell flex-column clickable" draggable="false" onClick={() => { showQuestion(question, 'grid_one_column_' + i + "_cell_" + index) }}>
                                                        <img draggable="false" className="profile_image" src={"/images/grind/profiles/" + question.image} alt={question.image.replace(".png", "")} />
                                                        <div draggable="false" className='profile_details flex-row'>
                                                            {question.online && (
                                                                <p className='online_circle'>•</p>
                                                            )}
                                                            {question.name && (
                                                                <p className='profile_name'>{question.name}</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            )}
                                        </div>
                                    </div>
                                </Fragment>
                            )}
                        </div>
                    )}
                    {current_grid === 2 && (
                        <div id="second_grid" className='grid flex-row'>
                            {grid_two.map((column, i) =>
                                <Fragment key={'grid_one_column_' + i}>
                                    <div className="grid_column flex-column">
                                        <div className='column_title flex-row' draggable="false" ><p>{column.category_name}</p></div>
                                        <div className='column_questions'>
                                            {column.questions.map((question, index) =>
                                                <Fragment key={'grid_one_column_' + i + "_cell_" + index}>
                                                    <div id={'grid_one_column_' + i + "_cell_" + index} className="grid_cell flex-column clickable" draggable="false" onClick={() => { showQuestion(question, 'grid_one_column_' + i + "_cell_" + index) }}>
                                                        <img draggable="false" className="profile_image" src={"/images/grind/profiles/" + question.image} alt={question.image.replace(".png", "")} />
                                                        <div draggable="false" className='profile_details flex-row'>
                                                            {question.online && (
                                                                <p className='online_circle'>•</p>
                                                            )}
                                                            {question.name && (
                                                                <p className='profile_name'>{question.name}</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </Fragment>
                                            )}
                                        </div>
                                    </div>
                                </Fragment>
                            )}
                        </div>
                    )}
                </div>
                <div className='teams_container flex-column'>
                    <div className='teams flex-column'>
                        {players.map((player, index) =>
                            <Fragment key={'team_points_' + index}>
                                <div className={`player flex-column ${mode}`}>
                                    <div className='player_name_overlay' style={{ color: player.player_color, borderColor: player.player_color, boxShadow: `${player.player_color} 0px 1px 10px 0px` }}><p>{player.player_name}</p></div>
                                    <div className='trapezoid flex-column' style={{ color: player.player_color, borderColor: player.player_color, boxShadow: `${player.player_color} 0px 1px 10px 0px` }}><p>{player.player_points}</p></div>
                                </div>
                            </Fragment>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
};

const WinnersModal = ({ players, setShowModal }) => {
    const navigate = useNavigate();
    return (
        <div className="winners_modal flex-column">
            <div className='modal-border flex-row'>
                <div className='modal-border-section' style={{ backgroundColor: "#ff002f" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#ffb300" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#fcff63" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#70ff63" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#63fff2" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#85d2ff" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#ff9cb4" }}></div>
            </div>
            <div className="question_modal_players flex-row">
            </div>
            <div className="winner_modal_text flex-column">
                <p className="winner_modal_winner_name" style={{ color: players[0].player_color, textShadow: `${players[0].player_color} 0px 1px 10px` }}>{players[0].player_name}</p>
                <p className="winner_modal_tagline" style={{ color: players[0].player_color, textShadow: `${players[0].player_color} 0px 1px 10px` }}>beat your asses!</p>
            </div>
            <div className="winner_modal_fates flex-column" style={{ border: `${players[0].player_color} 10px solid`,boxShadow: `${players[0].player_color} 0px 1px 10px 0px` }} >
                <div>
                    {players.map((player, index) =>
                        <>
                            {index != 0 && (
                                <Fragment key={'winner_player_' + index}>
                                    <hr />
                                    <p><span style={{ color: player.player_color, textShadow: `${player.player_color} 0px 1px 10px` }} className={`winners_modal_player_name`}>{player.player_name}</span> {player.player_fate}</p>
                                    <hr />
                                </Fragment>
                            )}
                        </>
                    )}
                </div>
            </div>
            <p className="winners_modal_home_link clickable" onClick={() => { setShowModal(false); navigate("/games") }}>Back To Games</p>
            <div className="question_modal_players flex-row">

            </div>
            <div className='modal-border flex-row'>
                <div className='modal-border-section' style={{ backgroundColor: "#ff002f" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#ffb300" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#fcff63" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#70ff63" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#63fff2" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#85d2ff" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#ff9cb4" }}></div>
            </div>
        </div>
    )
}

const QuestionModal = ({ question, players, givePoints, mode }) => {
    return (
        <div className="question_modal flex-column">
            {question.double && (
                <div id="daddy_screen" className='question_modal_daddy_screen' onClick={() => { document.getElementById("daddy_screen").classList.add("hidden") }}>
                    <div className='daddy-container flex-column'>
                        <img src={mode === "dark_mode" ? "/images/grind/sugar-daddy-dark.png" : "/images/grind/sugar-daddy-light.png"} />
                        <p className='subtitle black-text'>This question's points are double!</p>
                        <p className='link black-text'>click anywhere to continue</p>
                    </div>
                </div>
            )}
            <div className='modal-border flex-row'>
                <div className='modal-border-section' style={{ backgroundColor: "#ff002f" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#ffb300" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#fcff63" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#70ff63" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#63fff2" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#85d2ff" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#ff9cb4" }}></div>
            </div>
            <div className="question_modal_players flex-row">
                <div style={{ color: "#c7c7c7", borderColor: "#c7c7c7", textShadow: `#c7c7c7 0px 1px 10px`, boxShadow: `#c7c7c7 0px 1px 10px 0px` }} className={`question_modal_player ${question.double ? "green_points" : ""}`}> {question.points} Points</div>
            </div>
            <div className="question_modal_text flex-row">
                <p>{question.question_text}</p>
            </div>
            <div className="question_modal_players flex-row">
                {players.map((player, index) =>
                    <Fragment key={'question_player_' + index}>
                        <div style={{ color: player.player_color, borderColor: player.player_color, textShadow: `${player.player_color} 0px 1px 10px`, boxShadow: `${player.player_color} 0px 1px 10px 0px` }} className={`question_modal_player clickable`} onClick={() => { givePoints(index, question.points,) }}>{player.player_name}</div>
                        <p style={{ opacity: ".2" }}>|</p>
                    </Fragment>
                )}
                <div style={{ color: "#c7c7c7", borderColor: "#c7c7c7", textShadow: `#c7c7c7 0px 1px 10px`, boxShadow: `#c7c7c7 0px 1px 10px 0px` }} className={`question_modal_player clickable`} onClick={() => { givePoints(69, question.points) }}>No One</div>
            </div>
            <div className='modal-border bottom-border flex-row'>
                <div className='modal-border-section' style={{ backgroundColor: "#ff002f" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#ffb300" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#fcff63" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#70ff63" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#63fff2" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#85d2ff" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#ff9cb4" }}></div>
            </div>
        </div>
    )
}

export default Grind;