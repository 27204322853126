import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from "react-router-dom";
import './games.scss';
import { setGameID, getBackendUrl } from '../../components/GlobalFunctions/globalFunctions';
import HamburgerMenu from '../../components/Nav/hamburger-menu'

const Games = ({ mode, setMode, setShowModal }) => {
  const navigate = useNavigate();

  const games = [
    {
      game: "grind",
      holo: "holo-grind-light",
      holo_dark: "holo-grind-dark"
    },
    {
      game: "verse",
      holo: "holo-verses",
      holo_dark: null
    },
    {
      game: "dare",
      holo: "holo-dare-light",
      holo_dark: "holo-dare"
    }
  ]

  const [selectedGame, setSelectedGame] = useState("grind")
  const [selectedGameIndex, setSelectedGameIndex] = useState(0)
  const [grindEpisodes, setGrindEpisodes] = useState([])
  const [dareEpisodes, setDareEpisodes] = useState([])
  const [verseEpisodes, setVerseEpisodes] = useState([])


  useEffect(() => {
    setShowModal(false)
    getEpisodes()
    setGameID("")
  }, []);


  const chooseGame = (game) => {
    setSelectedGame(game.game)
    setSelectedGameIndex(games.indexOf(game))
  }

  const getGameHolo = (i) => {
     let index = i;
     if(i < 0){
       i = games.length - 1
     }
     if(i > games.length - 1){
      i = 0;
     }
     return games[i]
  }

  const getEpisodes = () => {


    let url = getBackendUrl() + '/api/games/0'
    fetch(url)
      .then(response => response.json())
      .then((jsonData) => {
        // jsonData is parsed json object received from url
        console.log(jsonData)
        setGrindEpisodes(jsonData.grind_games)
        setVerseEpisodes(jsonData.verse_games)
        setDareEpisodes(jsonData.dare_games)
      })
      .catch((error) => {
        // handle your errors here

        console.error(error)
      })
  }

  return (
    <div className={"games-page page " + mode}>
      <div className='games-bar flex-row'>
        <div className='hamburger-menu-container'>
          <HamburgerMenu mode={mode} setMode={setMode} isGames={true} />
        </div>
        <img alt="logo" src="/images/logos/rainbow_logo_long.png" />
      </div>
      <div className='games-menu flex-row'>
        <div className='games-menu-image left-image' onClick={()=>{chooseGame(getGameHolo(selectedGameIndex - 1))}}>
            <img alt="logo" src={`/images/games/${mode == "dark_mode" && getGameHolo(selectedGameIndex - 1).holo_dark ? getGameHolo(selectedGameIndex - 1).holo_dark : getGameHolo(selectedGameIndex - 1).holo}.png`} />
          </div>
          <div className='games-menu-image flex-row main-image' onClick={()=>{chooseGame(getGameHolo(selectedGameIndex))}}>
            <img alt="logo" src={`/images/games/${mode == "dark_mode" && getGameHolo(selectedGameIndex).holo_dark ? getGameHolo(selectedGameIndex).holo_dark : getGameHolo(selectedGameIndex).holo}.png`} />
          </div>
          <div className='games-menu-image right-image' onClick={()=>{chooseGame(getGameHolo(selectedGameIndex + 1))}}>
            <img alt="logo" src={`/images/games/${mode == "dark_mode" && getGameHolo(selectedGameIndex + 1).holo_dark ? getGameHolo(selectedGameIndex + 1).holo_dark : getGameHolo(selectedGameIndex + 1).holo}.png`} />
          </div>
      </div>
      <div className='episodes_section flex-column'>
        {selectedGame === "grind" && (
          <div className='episodes_container'>
            {grindEpisodes.map((episode, i) =>
              <Fragment key={"grind_episode_" + i}>
                <Episode episode={episode} mode={mode} navigate={navigate} />
              </Fragment>
            )}
          </div>
        )}
        {selectedGame === "dare" && (
          <div className='episodes_container'>
            {dareEpisodes.map((episode, i) =>
              <Fragment key={"dare_episode_" + i}>
                <Episode episode={episode} mode={mode} navigate={navigate} />
              </Fragment>
            )}
          </div>
        )}
        {selectedGame === "verse" && (
          <div className='episodes_container'>
            {verseEpisodes.map((episode, i) =>
              <Fragment key={"verse_episode_" + i}>
                <Episode episode={episode} mode={mode} navigate={navigate} />
              </Fragment>
            )}
          </div>
        )}
      </div>
    </div>
  )
};

const Episode = ({ episode, mode, navigate }) => {

  const pickEpisode = (id) => {
    setGameID(id)
    if (episode.game_type_id === 1) {
      navigate("/playgame/grind")
    } else if (episode.game_type_id === 2) {
      navigate("/playgame/dare")
    } else if (episode.game_type_id === 3) {
      navigate("/playgame/verse")
    }
  }

  return (
    <div className='episode clickable' onClick={() => { pickEpisode(episode.id) }}>
      <div className='episode_back flex-column'>
        {mode === "dark_mode" && (
          <img alt="logo" className='episode_back_image' src="/images/games/episode_thumb_dark.png" />
        )}
        {mode !== "dark_mode" && (
          <img alt="logo" className='episode_back_image' src="/images/games/episode_thumb.png" />
        )}
      </div>
      <div className='episode_rating'>
        <p>{episode.dirty_rating}</p>
      </div>
      <p className='episode_text'>{episode.episode_number ? "Episode " + episode.episode_number : ""}</p>
      <p className='episode_title'>{episode.game_title}</p>
      <div className='spacer'></div>
    </div>
  )


}

export default Games;