
import { shuffleArray } from '../GlobalFunctions/globalFunctions';
import './modals.scss';
import React, { useState, Fragment, useEffect } from 'react';

const PlayersModal = ({ modalButtonFunc, logo, darkLogo, mode, content, colors, prevPlayers }) => {



    const [playerCount, setPlayerCount] = useState(0)
    const [modalPlayers, setModalPlayers] = useState([])

    let actual_players = [];

    useEffect(() => {
        actual_players = []
        prevPlayers.forEach(element => {
            if(element.player_type == "player"){
                actual_players.push(element)
            }
        });
        if(actual_players.length > 2){
            setModalPlayers(prevPlayers)
            setupPlayerCount(actual_players.length, prevPlayers)
        }
    }, []);

    const setupPlayerCount = (count, arr=[]) => {
        setPlayerCount(count);
        let modalPlayersObj = []
        modalPlayersObj = [];

        actual_players = []

        if(arr.length > 0){
            arr.forEach(element => {
                if(element.player_type == "player"){
                    actual_players.push(element)
                }
            });
        }else{
            modalPlayers.forEach(element => {
                if(element.player_type == "player"){
                    actual_players.push(element)
                }
            });
        }

        let shuffled_colors = shuffleArray(colors)
        for (let i = 0; i < 12; i++) {
            let valid = i < count
           let  player_name = valid ? "" : "Player's Choice"
            if(valid && i < actual_players.length){
                player_name = actual_players[i].player_name
            }

            modalPlayersObj.push({
                player_id: i,
                sort_order: i,
                player_name: player_name,
                player_color: "",
                player_type: valid ? "player" : "blank",
            })
        }
        let shuffled_players = shuffleArray(modalPlayersObj)

        shuffled_players.forEach(element => {
            element.player_color = shuffled_colors[element.player_id]
        });
        setModalPlayers(shuffled_players)
    }

    const checkPlayers = () => {
        let can_play = true;
        if (modalPlayers.length < 2) {
            can_play = false;
        }
        let copy = [...modalPlayers]
        let i = 0;
        copy.forEach(element => {
            if (element.player_name === "" || element.player_name === null || element.player_name === undefined) {
                let input = document.getElementById("player_name_" + i);
                if(input.getAttribute("placeholder") != ""){
                    element.player_name = input.getAttribute("placeholder")
                }else{
                    can_play = false;
                }
            }
            i += 1;
        });
        setModalPlayers(copy)
        return can_play
    }

    const changePlayerName = (i) => {
        let input = document.getElementById("player_name_" + i)
        let copy = [...modalPlayers]
        if(input.value != "" && input.value != undefined){
            copy[i].player_name = input.value
            setModalPlayers(copy)
        }
    }

    const savePlayers = () => {
        if (checkPlayers()) {
            modalButtonFunc(modalPlayers)
        }
    }

    return (
        <div className={"modal players_modal flex-column " + mode}>
            <div className='modal-border flex-row'>
                <div className='modal-border-section' style={{ backgroundColor: "#ff002f" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#ffb300" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#fcff63" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#70ff63" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#63fff2" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#85d2ff" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#ff9cb4" }}></div>
            </div>
            <div className='modal_content-scroll flex-column'>
            <div className='modal_content'>
            <div className='flex-column'>
            <img alt="logo" className={"logo light_logo "} src={logo} />
            <img alt="logo" className={"logo dark_logo "} src={darkLogo} />

            <p className="title black_text">Player Setup</p>
            <div className='content'>
                {content}
            </div>
            <p className="header black_text">How Many Players?</p>
            <div className="players-row fill_parent flex-row">
                <div style={{ color: colors[0], textShadow: `2px 2px 5px ${colors[0]}` }} className={"player_number_button clickable " + `${playerCount !== 3 && playerCount !== 0 ? "unselected" : ""} ${playerCount === 3 ? "selected" : ""}`} onClick={() => { setupPlayerCount(3) }}>3</div>
                <div style={{ color: colors[1], textShadow: `2px 2px 5px ${colors[1]}` }} className={"player_number_button clickable " + `${playerCount !== 4 && playerCount !== 0 ? "unselected" : ""} ${playerCount === 4 ? "selected" : ""}`} onClick={() => { setupPlayerCount(4) }}>4</div>
                <div style={{ color: colors[2], textShadow: `2px 2px 5px ${colors[2]}` }} className={"player_number_button clickable " + `${playerCount !== 5 && playerCount !== 0 ? "unselected" : ""} ${playerCount === 5 ? "selected" : ""}`} onClick={() => { setupPlayerCount(5) }}>5</div>
                <div style={{ color: colors[3], textShadow: `2px 2px 5px ${colors[3]}` }} className={"player_number_button clickable " + `${playerCount !== 6 && playerCount !== 0 ? "unselected" : ""} ${playerCount === 6 ? "selected" : ""}`} onClick={() => { setupPlayerCount(6) }}>6</div>
                <div style={{ color: colors[4], textShadow: `2px 2px 5px ${colors[4]}` }} className={"player_number_button clickable " + `${playerCount !== 7 && playerCount !== 0 ? "unselected" : ""} ${playerCount === 7 ? "selected" : ""}`} onClick={() => { setupPlayerCount(7) }}>7</div>
                <div style={{ color: colors[5], textShadow: `2px 2px 5px ${colors[5]}` }} className={"player_number_button clickable " + `${playerCount !== 8 && playerCount !== 0 ? "unselected" : ""} ${playerCount === 8 ? "selected" : ""}`} onClick={() => { setupPlayerCount(8) }}>8</div>
            </div>
            <div className="players-row fill_parent flex-row">
                <div style={{ color: colors[6], textShadow: `2px 2px 5px ${colors[6]}` }} className={"player_number_button clickable " + `${playerCount !== 9 && playerCount !== 0 ? "unselected" : ""} ${playerCount === 9 ? "selected" : ""}`} onClick={() => { setupPlayerCount(9) }}>9</div>
                <div style={{ color: colors[7], textShadow: `2px 2px 5px ${colors[7]}` }} className={"player_number_button clickable " + `${playerCount !== 10 && playerCount !== 0 ? "unselected" : ""} ${playerCount === 10 ? "selected" : ""}`} onClick={() => { setupPlayerCount(10) }}>10</div>
                <div style={{ color: colors[8], textShadow: `2px 2px 5px ${colors[8]}` }} className={"player_number_button clickable " + `${playerCount !== 11 && playerCount !== 0 ? "unselected" : ""} ${playerCount === 11 ? "selected" : ""}`} onClick={() => { setupPlayerCount(11) }}>11</div>
                <div style={{ color: colors[11], textShadow: `2px 2px 5px ${colors[11]}` }} className={"player_number_button clickable " + `${playerCount !== 12 && playerCount !== 0 ? "unselected" : ""} ${playerCount === 12 ? "selected" : ""}`} onClick={() => { setupPlayerCount(12) }}>12</div>
            </div>

            {playerCount >= 2 && (
                <div className='player_inputs_container'>
                    <hr />
                    <div className='name_inputs'>
                        {modalPlayers.map((player, i) =>
                            <Fragment key={"dare_name_input_" + i}>
                                {player.player_type == "player" && (
                                    <div className='player_name_div'>
                                        <input autoComplete="off" id={"player_name_" + i} className={`standard-input name-input ${player.player_type == "player" ? "" : "hidden"}`} placeholder={player.player_name} style={{ color: player.player_color, borderColor: player.player_color }} type="text" maxLength={20} onBlur={() => { changePlayerName(i) }} />
                                    </div>
                                )}
                            </Fragment>
                        )}
                    </div>
                    <hr />
                </div>
            )}

            <div className='modal-buttons flex-row' >
                <button id="play_button" className={`${playerCount < 2 ? "hidden " : " "} modal-button standard-button`} onClick={() => { savePlayers() }}>Let's Play!</button>
            </div>
            </div>
            </div>
            </div>
            <div className='modal-border bottom-border flex-row'> <div className='modal-border-section' style={{ backgroundColor: "#ff002f" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#ffb300" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#fcff63" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#70ff63" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#63fff2" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#85d2ff" }}></div>
                <div className='modal-border-section' style={{ backgroundColor: "#ff9cb4" }}></div></div>
        </div>
    )
}

export default PlayersModal