
import './hamburger-menu.scss';
import { setDisplayMode} from '../../components/GlobalFunctions/globalFunctions';
import { useNavigate } from 'react-router-dom';

const HamburgerMenu = ({ mode, setMode, isGames }) => {
    const navigate = useNavigate();
    return(
        <div>
            <nav role="navigation">
            <div id="menuToggle">
                <input id="menu_cb" type="checkbox" />
                <span></span>
                <span></span>
                <span></span>
                <ul id="menu">
                {mode === "dark_mode" && (
                <li onClick={() => { setDisplayMode("light_mode"); setMode("light_mode"); document.getElementById('menu_cb').click()}}>Switch to light mode</li>
                )}
                {mode !==  "dark_mode" && (
                <li onClick={() => { setDisplayMode("dark_mode"); setMode("dark_mode");document.getElementById('menu_cb').click() }}>Switch to dark mode</li>
                )}
                {!isGames && (
                <li onClick={() => { navigate("/games")}}>Back to Home</li>
                )}
                <a href="/logout"><li>Logout</li></a>
                </ul>
            </div>
            </nav>
        </div>
    )
}

export default HamburgerMenu