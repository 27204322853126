
export function getBackendUrl(){
  let url = 'https://backend.chaotictop.com'
  //url = 'http://127.0.0.1:8000'
  return url
}

export function getAnswerUrl(){
  let url = ''
  return url;
}

export function setDisplayMode(val) {
    return localStorage.setItem("dark-mode", val)
}

export function getDisplayMode() {
    return localStorage.getItem("dark-mode")
}

export function setGameID(val) {
    return localStorage.setItem("game-id", val)
}

export function getGameID() {
    return localStorage.getItem("game-id")
}

export function shuffleArray(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex > 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }

  export function sortArrayAsc(array, property) {
    function compare( a, b ) {
      if ( a[property] < b[property] ){
        return -1;
      }
      if ( a[property] > b[property] ){
        return 1;
      }
      return 0;
    }
    
    return array.sort( compare );
  }

  export function sortArrayDesc(array, property) {
    function compare( a, b ) {
      if ( a[property] > b[property] ){
        return -1;
      }
      if ( a[property] < b[property] ){
        return 1;
      }
      return 0;
    }
    
    return array.sort( compare );
  }

  export function getRandomInt(max){
    return Math.floor(Math.random() * max)
  }