import React, { Fragment, useEffect, useState } from 'react';
import './dare.scss';
import { getGameID, getRandomInt, shuffleArray, sortArrayDesc, getBackendUrl, getAnswerUrl, sortArrayAsc } from '../../../components/GlobalFunctions/globalFunctions';
import InstructionsModal from '../../../components/Modals/instructions-modal';
import PlayersModal from '../../../components/Modals/dare-players-modal';
import SettingsModal from '../../../components/Modals/dare-settings-modal';
import HamburgerMenu from '../../../components/Nav/hamburger-menu';
const Dare = ({ mode, setMode, setModalContent, setShowModal, showModal }) => {

    const colors = ["#ff3e2f", "#ff4c1f", "#fff753", "#84ff2f", "#43ff22", "#1bffa1", "#4bffe9", "#3798ff", "#7c51ff", "#ff4dcd", "#ff3c70", "#ff4132"]

    const mild_drinks = [
        "drink-1-sip",
        "drink-2-sip",
        "drink-3-sip",
        "drink-4-sip",
        "drink-5-sip",
        "drink-6-sip",
        "drink-8-sip",
        "drink-10-sip",
    ]

    const medium_drinks = [
        "drink-2-sip",
        "drink-4-sip",
        "drink-6-sip",
        "drink-8-sip",
        "drink-1-gulp",
        "drink-2-gulp",
        "drink-3-gulp",
        "drink-1-drink",
        "drink-1-shot",
    ]

    const spicy_drinks = [
        "drink-2-sip",
        "drink-1-gulp",
        "drink-0.5-shot",
        "drink-1-shot",
        "drink-2-gulp",
        "drink-1.5-shot",
        "drink-2-shot",
        "drink-1-drink",
        "drink-2.5-shot",
        "drink-3-shot",
    ]

    const [gameLoaded, setGameLoaded] = useState(false)
    const [cardsLoaded, setCardsLoaded] = useState(false)
    const [players, setPlayers] = useState([])
    const [playerOrder, setPlayerOrder] = useState([])
    const [gameMode, setGameMode] = useState("classic")
    const [gameLevel, setGameLevel] = useState("mild")

    const [truthCards, setTruthCards] = useState([])
    const [dareCards, setDareCards] = useState([])
    const [doCards, setDoCards] = useState([])
    const [drinkCards, setDrinkCards] = useState([])

    const [gameDrinks, setGameDrinks] = useState(mild_drinks)
    const [gameDrinkIndex, setGameDrinkIndex] = useState(0)
    const [currentCard, setCurrentCard] = useState({})

    let isSpinning = false;
    let spinDegree = 0;
    const spinSpeed = 5;
    let diff = 0;
    let old_slice = 0;
    let settings_source = "init";

    useEffect(() => {
        setModalContent(
            <InstructionsModal
                modalButtonFunc={showPlayerModal}
                logo={"/images/dare/dare-logo.png"}
                darkLogo={"/images/dare/dare-logo-dark.png"}
                mode={mode}
                content={
                    <div className='black_text instructions_content'>
                        <div className='section flex-column'>
                            <p className='words'>This game is an adult version of "Truth or Dare"!</p>
                        </div>
                        <div className='section flex-column'>
                            <p className='header'>Setting Up</p>
                            <p className='words'>First things first we need to know what to call you! Have the game master enter your nickname on the player creation screen</p>
                            <p className='words'>There are two ways to play this game: Classic and Revenge.<br></br><br></br> You can pick the gamemode and level of naughtiness on the next screen.</p>
                        </div>
                        <div className='section flex-column'>
                            <p className='header'>Classic</p>
                            <p className='words'>This mode follows the standard "Truth or Dare" premise. Players will rotate through in circular order, spinning the wheel in the middle to determine which card they will pull. They cannot choose drink.</p>
                        </div>
                        <div className='section flex-column'>
                            <p className='header'>Revenge</p>
                            <p className='words'>This mode is the opposite! The wheel is spun to determine which player goes next! Then the group gets to gang up and choose which level card that player must complete! </p>
                        </div>
                        <div className='section flex-column'>
                            <p className='header'>The Board</p>
                            <p className='words'>The game board is split up into three sections: The Bar, The Wheel, and The Cards </p>
                            <p className='words'><b>The Bar:   </b>The bar on the left side keeps track of the drinking penalty. The penalty will increase with every successfully completed challenge until it hits the max amount. The amount that is increased with each drink and the cap are determined by the naughtiness level you chose.</p>
                            <p className='words'>Underneath the bar there is a button to reopen the settings and adjust the level and game mode. <i>Note: Changing settings will restart the game with new cards and the drink penalty will also be reset</i></p>
                            <br></br>
                            <p className='words'><b>The Wheel:   </b>In the middle of the board is the wheel. Simply click on the circle in the middle to spin the arrow. Then click again to stop!</p>
                            <p className='words'><i>Classic:</i> The person who's turn it is will tell the game master when to stop. The selected element is what card they have to draw</p>
                            <p className='words'><i>Revenge:</i> The game master will spin the wheel, and whoever completed the last challenge successfully will get to choose when it stops. The selected element is the player that has to go next. If the wheel lands on a slice that does not apply to any player then the last player to successfully complete a challenge gets to choose who goes next!</p>
                            <br></br>
                            <p className='words'><b>The Cards:   </b>On the right side are the four decks of cards, Truth, Dare, Do, or Drink. When a person and their challenge are chosen the game master will draw a card: </p>
                            <p className='words'><i>Truth: </i>The card will ask a question to the player and they have to answer it truthfully. If they choose not to answer or everyone can tell it's an obvious lie they get a <b>DRINK</b> penalty</p>
                            <p className='words'><i>Dare: </i>The card will give a one time dare. If they choose to not do the dare, then they get a <b>DRINK</b> penalty</p>
                            <p className='words'><i>Do: </i>The card will give them a recurring challenge. These are less intense than the Dare cards but go on for longer. If they fail the challenge they get a <b>DRINK</b> penalty</p>
                            <p className='words'><i>Drink: </i>This card is drawn when a player lands on Drink. These cards can have three possibilities: Jackpot, Cashout, and Bankrupt.
                                <br></br>Jackpot will increase the amount in the bar, but the player who drew the card does not have to drink it this turn.
                                <br></br>Cashout means the player has to drink the amount listed on the card. The amount in the bar does not change.
                                <br></br>Bankrupt will decrease the amount in the bar back to it's first level. The player who drew the card does not have to drink it.
                            </p>
                            <br></br>
                            <p className='words'><b><i>DRINK PENALTY - </i></b>When a player gets a drink penalty they have to drink the amount that is currently shown on the bar. If you are sober or too intoxicated then you drink a third of the amount shown of Lemon Juice straight.</p>
                        </div>
                    </div>
                } />
        )
        setShowModal(true)
    }, []);

    const getImgBaseUrl = (mode) => {
        return `/images/dare/wheel/${mode}/`;
    }

    const getSliceData = (i) => {
        let text = ""
        let color = ""
        if (gameMode == "revenge") {
            text = players[i].player_name
            color = players[i].player_color
        } else {
            let j = i;
            while (j >= 4) {
                j = j - 4
            }
            if (j == 0) {
                color = "#ff0800"
                text = "Dare"
            } else if (j == 1) {
                color = "#ffd500"
                text = "Do"
            } else if (j == 2) {
                color = "#3fa8ff"
                text = "Drink"
            } else if (j == 3) {
                color = "#71ff58"
                text = "Truth"

            }
        }
        let element = document.getElementById('display_text')
        element.style.color = color;
        element.innerHTML = text
    }

    const showPlayerModal = () => {
        setShowModal(false)
        setModalContent(<PlayersModal
            colors={colors}
            modalButtonFunc={setupPlayers}
            logo={"/images/dare/dare-logo.png"}
            darkLogo={"/images/dare/dare-logo-dark.png"}
            mode={mode}
            prevPlayers={players} />)
        setShowModal(true)
    }

    const setupPlayers = (player_array) => {
        setPlayers(player_array)
        let copy = []
        player_array.forEach(element => {
            if (element.player_type == "player") {
                element.sort_order = element.player_id + 1
                copy.push(element)
            }
        });
        setPlayerOrder(copy)
        setShowModal(false)
        if (settings_source == "init") {
            showSettings("init")
        }
    }

    const showSettings = (source) => {
        let button = "Start New Game"
        if (source == "init") {
            button = "Start Game"
        }
        setModalContent(<SettingsModal
            modalButtonFunc={setupSettings}
            logo={"/images/dare/dare-logo.png"}
            darkLogo={"/images/dare/dare-logo-dark.png"}
            mode={mode}
            source={source}
            buttonText={button} />)
        setShowModal(true)
    }

    const wheelClicked = () => {
        if (isSpinning) {
            stopWheelSpin()
        } else {
            startWheelSpin()
        }
    }

    const setupSettings = (game_mode, level) => {
        setShowModal(false)
        setGameMode(game_mode)
        setGameLevel(level)
        setGameDrinkIndex(0)
        getGame(game_mode, level)
        var drinks = medium_drinks;
        if (level == "spicy") {
            drinks = spicy_drinks
        } else if (level == "mild") {
            drinks = mild_drinks
        }
        setGameDrinks(drinks)
    }

    const startWheelSpin = () => {
        diff = 5;
        old_slice = getActiveSlice(spinDegree);
        let old_src = document.getElementById('wheel_slice_' + old_slice).getAttribute('src').replace("-active", "-regular")
        document.getElementById('wheel_slice_' + old_slice).setAttribute("src", old_src)

        isSpinning = true
        let element = document.getElementById('wheel_arrow')
        document.getElementById('wheel_overlay').classList.remove('hidden')
        spinWheel(spinDegree, element)
    }

    const stopWheelSpin = () => {
        isSpinning = false
    }

    const spinWheel = (degree, element) => {
        spinDegree = degree;
        if (degree % 30 == 0) {
            changeSlice()
        }

        if (isSpinning) {
            element.style = `transform:rotate(${degree}deg)`
            setTimeout(() => { spinWheel(degree + 7, element) }, spinSpeed)
        } else {
            if (diff > 0) {
                diff = diff - 1
                element.style = `transform:rotate(${degree}deg)`
                setTimeout(() => { spinWheel(degree + 7, element) }, spinSpeed + 15 - diff)
            } else {
                spinDegree = spinDegree % 360
                if (spinDegree % 30 == 0) {
                    spinDegree = spinDegree - 2
                    element.style = `transform:rotate(${spinDegree}deg)`
                }
                changeSlice()
                document.getElementById('wheel_overlay').classList.add('hidden')
            }
        }
    }

    const changeSlice = () => {
        let old_src = document.getElementById('wheel_slice_' + old_slice).getAttribute('src').replace("-active", "-regular")
        document.getElementById('wheel_slice_' + old_slice).setAttribute("src", old_src)
        let active_slice = getActiveSlice(spinDegree % 360)
        let src = document.getElementById('wheel_slice_' + active_slice).getAttribute('src').replace("-regular", "-active")
        document.getElementById('wheel_slice_' + active_slice).setAttribute("src", src)
        old_slice = active_slice
        getSliceData(active_slice)
    }

    const getActiveSlice = (deg) => {
        let this_active_slice = 2
        if (deg < 180) {
            if (deg < 90) {
                if (deg < 30) {
                    this_active_slice = 0
                } else if (spinDegree < 60) {
                    this_active_slice = 1
                }
            } else {
                if (deg < 120) {
                    this_active_slice = 3
                } else if (spinDegree < 150) {
                    this_active_slice = 4
                } else {
                    this_active_slice = 5
                }
            }
        } else {
            if (deg < 270) {
                if (deg < 210) {
                    this_active_slice = 6
                } else if (spinDegree < 240) {
                    this_active_slice = 7
                } else {
                    this_active_slice = 8
                }
            } else {
                if (deg < 300) {
                    this_active_slice = 9
                } else if (spinDegree < 330) {
                    this_active_slice = 10
                } else {
                    this_active_slice = 11
                }
            }
        }
        return this_active_slice
    }

    const getGame = (mode, level) => {
        setGameLoaded(false)
        let url = `${getBackendUrl()}/api/dare/${getGameID()}/${level}`
        console.log(url)

        if (settings_source != "init") {
            setTimeout(() => {
                resetDeck("truth")
                resetDeck("dare")
                resetDeck("do")
                resetDeck("drink")
            }, 500)
        }

        fetch(url)
            .then(response => response.json())
            .then((jsonData) => {
                // jsonData is parsed json object received from url
                console.log(jsonData)
                try {
                    let new_drinks = []
                    let i = 0;
                    jsonData.drink_cards.forEach(element => {
                        i++;
                        if (i % 2 == 0) {
                            new_drinks.push({
                                "card_type": "drink",
                                "card_text": "Cash Out!",
                                "card_action": "cashout",
                                "card_subtext": "Uh Oh! It's time to cash in your chips. You have to drink the current penalty amount :("
                            })
                        }
                        new_drinks.push({
                            "card_type": "drink",
                            "card_text": "Bankrupt!",
                            "card_action": "bankrupt",
                            "card_subtext": "Congrats! You made the bar go bankrupt, now the drink penalty will be reset!"
                        })
                        new_drinks.push(element)
                    });

                    setTruthCards(jsonData.truth_cards)
                    setDoCards(jsonData.do_cards)
                    setDareCards(jsonData.dare_cards)
                    setDrinkCards(new_drinks)

                    setGameLoaded(true)
                    setCardsLoaded(true)

                } catch (error) {
                    setGameLoaded(false)
                    setCardsLoaded(false)
                }

            })
            .catch((error) => {
                // handle your errors here
                console.error(error)
                setGameLoaded(false)
            })
    }

    const completeChallenge = () => {
        rotatePlayers()
        increaseDrink()
    }

    const increaseDrink = () => {
        let index = gameDrinkIndex + 1;
        if (index < gameDrinks.length) {
            setGameDrinkIndex(index)
        }
    }

    const decreaseDrink = () => {
        let index = gameDrinkIndex - 1;
        if (index >= 0) {
            setGameDrinkIndex(index)
        }
    }

    const resetDrink = () => {
        setGameDrinkIndex(0)
    }

    const randomDrink = () => {
        let i = getRandomInt(gameDrinks.length)
        if (i < 0 || i > gameDrinks.length - 1) {
            i = 0
        }
        setGameDrinkIndex(i)
    }

    const rotatePlayers = () => {
        let copy = [...playerOrder]
        copy[0].sort_order = copy[0].sort_order * 10;
        console.log(copy)
        setPlayerOrder(sortArrayAsc(copy, "sort_order"))
    }

    const showCard = (card, element_id) => {
        document.getElementById(element_id).classList.add('card-fly-off')
        document.getElementById(element_id).classList.remove('visible-card')
        setCurrentCard(card)
        setTimeout(() => {
            document.getElementById('card_overlay').classList.remove('card_overlay_offscreen')
        }, 750)

    }

    const hideCard = () => {
        if (currentCard.card_type == "drink") {
            if (currentCard.card_action == "bankrupt") {
                resetDrink()
            }
        }
        if (gameMode == "classic") {
            rotatePlayers()
        }
        document.getElementById('card_overlay').classList.add('card_overlay_offscreen')
    }

    const resetDeck = (deck) => {
        console.log(deck)
        if (deck == "truth") {
            let new_deck = [...truthCards]
            new_deck = shuffleArray(new_deck)
            setTruthCards(new_deck)
        } else if (deck == "dare") {
            let new_deck = [...dareCards]
            new_deck = shuffleArray(new_deck)
            setDareCards(new_deck)
        } else if (deck == "do") {
            let new_deck = [...doCards]
            new_deck = shuffleArray(new_deck)
            setDoCards(new_deck)
        } else if (deck == "drink") {
            let new_deck = [...drinkCards]
            new_deck = shuffleArray(new_deck)
            setDrinkCards(new_deck)
        }

        let cards = document.getElementsByClassName(`${deck}_card`)
        for (let i = 0; i < cards.length; i++) {
            setTimeout(() => {
                if(cards[i] != undefined){
                    cards[i].classList.remove("card-fly-off")
                }
            }, 10 * i)

        }

    }

    const jackpotChallengeStatus = (status) => {
        if (status) {
            decreaseDrink()
        } else {
            randomDrink()
        }
        hideCard()
    }


    const challengeStatus = (status) => {
        if (status) {
            increaseDrink()
        }
        hideCard()
    }

    return (
        <div className={"dare-page page "}>
            <div id="card_overlay" className={"card_overlay card_overlay_offscreen "} onClick={() => { if (currentCard.card_type != "dare" && currentCard.card_type != "truth") hideCard(); }}>
                <div className='card_overlay_content_container flex-row'>
                    <img className='card_overlay_image' src={`/images/dare/cards/cardfront-${currentCard.card_type}-${mode}.png`} />
                    <div className='card_overlay_content flex-column black_text'>
                        <div className='card_overlay_inner_content black_text flex-column'>
                            <p className={`card_text ${currentCard.card_type}_card_title`}>{currentCard.card_text}</p>
                            {(currentCard.card_type == "dare" || currentCard.card_type == "truth") && (
                                <div className='card_overlay_options flex-row black_text'>
                                    <p className='clickable card_challenge_button challenge_failed_button' onClick={() => { challengeStatus(false) }}>Challenge Failed</p>
                                    <p className='clickable card_challenge_button challenge_completed_button' onClick={() => { challengeStatus(true) }}>Challenge Completed</p>
                                </div>
                            )}
                            {(currentCard.card_type == "drink") && (
                                <>
                                    <div className='card_subtext flex-column' style={{ fontSize: "3rem" }}>
                                        <p className={`${currentCard.card_action == "jackpot" ? "jackpot_subtext" : "hidden"}`}>It's time to roll the dice! If you can complete this challenge below you will decrease the drink penalty by 1 level. If you fail, then you will have to drink a randomly selected penalty from the bar! After which the penalty will be reset to the first level.</p>
                                        <p className='card_subtitle'>{currentCard.card_subtext}</p>
                                    </div>

                                    {currentCard.card_action == "jackpot" && (
                                        <div className='card_overlay_options flex-row black_text'>
                                            <p className='clickable card_challenge_button challenge_failed_button' onClick={() => { jackpotChallengeStatus(false) }}>Challenge Failed</p>
                                            <p className='clickable card_challenge_button challenge_completed_button' onClick={() => { jackpotChallengeStatus(true) }}>Challenge Completed</p>
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div id="wheel_overlay" className={"page-foreground-image hidden"} onClick={() => { stopWheelSpin() }}></div>
            <img alt="background" className={"page-background-image"} src={"/images/dare/dare-background.jpg"} />
            <div className={`dare-container flex-row ${mode} visible ${gameLoaded || cardsLoaded ? "" : "invisible"}`}>
                <div className='hamburger-menu-container'>
                    <HamburgerMenu mode={mode} setMode={setMode} isGames={false} />
                </div>
                <div className='bar flex-column'>
                    <div className='penalty-zone'>
                        <div className='penalty-images flex-column'>
                            <img alt="drink-penalty" src={`/images/dare/bar/${mode}/${gameDrinks[gameDrinkIndex]}.png`} />
                            <div className='flex-row penalty-buttons'>
                                <img alt="down" src={`/images/dare/bar/${mode}/button-down.png`} className={`${gameDrinkIndex > 0 ? "clickable" : "inactive"}`} onClick={() => { decreaseDrink() }} />
                                <img alt="reset" src={`/images/dare/bar/${mode}/button-reset.png`} className={`${gameDrinkIndex > 0 ? "clickable" : "inactive"}`} onClick={() => { resetDrink() }} />
                                <img alt="random" src={`/images/dare/bar/${mode}/button-random.png`} className={`clickable`} onClick={() => { randomDrink() }} />
                                <img alt="up" src={`/images/dare/bar/${mode}/button-up.png`} className={`${gameDrinkIndex < gameDrinks.length - 1 ? "clickable" : "inactive"}`} onClick={() => { increaseDrink() }} />
                            </div>
                        </div>
                    </div>
                    {gameMode == "classic" && (
                        <div className='players '>
                            <img alt="players" src={`/images/dare/bar/${mode}/players-title.png`} />
                            {playerOrder.map((player, i) =>
                                <Fragment key={'player_order_' + i}>
                                    {player.player_type == "player" && (
                                        <div style={{ color: player.player_color, borderColor: player.player_color, backgroundColor: player.player_color + "90", width: `${80 - (i * 7)}%` }} className={`player_order_tile`} onClick={() => { if (i == 0) completeChallenge() }}>
                                            <p>{player.player_name}</p>
                                        </div>
                                    )}
                                </Fragment>
                            )}
                        </div>
                    )}
                    <div className='game-settings flex-column'>
                        <img alt="game-settings" src={`/images/dare/bar/${mode}/button-settings.png`} className={"clickable game_settings settings_button"} onClick={() => { settings_source = "button"; showSettings() }} />
                        <img alt="game-settings" src={`/images/dare/bar/${mode}/button-players.png`} className={"clickable settings_button player_settings"} onClick={() => { settings_source = "button"; showPlayerModal() }} />
                    </div>
                </div>
                <div className='wheel flex-column'>
                    <div className='text_display_container'>
                        <img alt="display_grid" className='overlay_frame' src={getImgBaseUrl(mode) + "text-frame.png"} />
                        <img alt="display_grid" className='overlay_graphic' src={getImgBaseUrl(mode) + "text-grid.png"} />
                        <div className='overlay_content flex-row'><p id={'display_text'} className={`black_text display_text_screen`}>Spin The Wheel!</p></div>
                        <img alt="display_grid" className='overlay_back' src={getImgBaseUrl(mode) + "text-back.png"} />
                        <img alt="display_grid" className='overlay_sizer' src={getImgBaseUrl(mode) + "text-back.png"} />
                    </div>
                    <div className='wheel_container flex-row' onClick={() => { wheelClicked() }}>
                        <img alt="display_grid" className='overlay_frame' src={getImgBaseUrl(mode) + "wheel-frame.png"} />
                        <img alt="display_grid" id="wheel_arrow" className='overlay_graphic' src={getImgBaseUrl(mode) + "wheel-arrow.png"} />
                        {<div className='overlay_content flex-row'>
                            {players.map((player, i) =>
                                <Fragment key={'wheel_slice_' + i}>
                                    <img alt="display_grid" id={'wheel_slice_' + i} className='wheel_slice' src={`${getImgBaseUrl(gameMode)}slice-${i + 1}-regular.png`} />
                                </Fragment>
                            )}
                        </div>}
                        <img alt="display_grid" className='overlay_back' src={getImgBaseUrl(mode) + "wheel-backing.png"} />
                        <img alt="display_grid" className='overlay_sizer' src={getImgBaseUrl(mode) + "wheel-backing.png"} />
                    </div>
                </div>
                <div className='cards flex-column'>
                    <div className='deck truth-deck'>
                        <img className='empty_card_image' src={`/images/dare/cards/cardback-truth-empty-${mode}.png`} onClick={() => { resetDeck("truth") }} />
                        {truthCards.map((card, i) =>
                            <Fragment key={'truth_card' + i}>
                                <img alt={`${card.card_type} card`} id={'truth_card_' + i} onClick={() => { showCard(card, 'truth_card_' + i) }} style={{ right: `${i < 50 ? (i) * 2 : "0"}px` }} className={`${card.card_type}_card card_image`} src={`/images/dare/cards/cardback-truth.png`} />
                            </Fragment>
                        )}
                    </div>
                    <div className='deck dare-deck'>
                        <img className='empty_card_image' src={`/images/dare/cards/cardback-dare-empty-${mode}.png`} onClick={() => { resetDeck("dare") }} />
                        {dareCards.map((card, i) =>
                            <Fragment key={'dare_card' + i}>
                                <img alt={`${card.card_type} card`} id={'dare_card_' + i} onClick={() => { showCard(card, 'dare_card_' + i) }} style={{ right: `${i < 50 ? (i) * 2 : "0"}px` }} className={`${card.card_type}_card card_image`} src={`/images/dare/cards/cardback-dare.png`} />
                            </Fragment>
                        )}
                    </div>
                    <div className='deck do-deck'>
                        <img className='empty_card_image' src={`/images/dare/cards/cardback-do-empty-${mode}.png`} onClick={() => { resetDeck("do") }} />
                        {doCards.map((card, i) =>
                            <Fragment key={'do_card' + i}>
                                <img alt={`${card.card_type} card`} id={'do_card_' + i} onClick={() => { showCard(card, 'do_card_' + i) }} style={{ right: `${i < 50 ? (i) * 2 : "0"}px` }} className={`${card.card_type}_card card_image`} src={`/images/dare/cards/cardback-do.png`} />
                            </Fragment>
                        )}
                    </div>
                    <div className='deck drink-deck'>
                        <img className={`empty_card_image`} src={`/images/dare/cards/cardback-drink-empty-${mode}.png`} onClick={() => { resetDeck("drink") }} />
                        {drinkCards.map((card, i) =>
                            <Fragment key={'drink_card' + i}>
                                <img alt={`${card.card_type} card`} id={'drink_card_' + i} onClick={() => { showCard(card, 'drink_card_' + i) }} style={{ right: `${i < 50 ? (i) * 2 : "0"}px` }} className={`${card.card_type}_card card_image visible-card`} src={`/images/dare/cards/cardback-drink.png`} />
                            </Fragment>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Dare;