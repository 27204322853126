import React, { Fragment, useEffect, useState } from 'react';
import './verse.scss';
import { getGameID, getRandomInt, shuffleArray, sortArrayDesc, getBackendUrl, getAnswerUrl } from '../../../components/GlobalFunctions/globalFunctions';
import InstructionsModal from '../../../components/Modals/instructions-modal';
import HamburgerMenu from '../../../components/Nav/hamburger-menu';
import { useNavigate } from 'react-router-dom';
import PlayersModal from '../../../components/Modals/verse-players-modal';

const Verse = ({ mode, setMode, setModalContent, setShowModal, showModal }) => {

    const [gameLoaded, setGameLoaded] = useState(false)
    const [grids, setGrids] = useState([])
    const [roundWinners, setRoundWinners] = useState(["", "", ""])
    const [winner, setWinner] = useState("")
    const [topPlayers, setTopPlayers] = useState([])
    const [bottomPlayers, setBottomPlayers] = useState([])
    const [currentGrid, setCurrentGrid] = useState(0)
    const [showQuestion, setShowQuestion] = useState(false)
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
    const [currentQuestion, setCurrentQuestion] = useState()
    const [currentFinalQuestionIndex, setCurrentFinalQuestionIndex] = useState(0)
    const [topPoints, setTopPoints] = useState(10)
    const [bottomPoints, setBottomPoints] = useState(10)
    const [totalPoints, setTotalPoints] = useState(20)
    const [topLives, setTopLives] = useState(3)
    const [bottomLives, setBottomLives] = useState(3)
    const [outstandingPoints, setOutstandingPoints] = useState(0)
    const [missedPoints, setMissedPoints] = useState(0)
    const [fightMode, setFightMode] = useState("regular")
    const [showContent, setShowContent] = useState("regular")
    const [currentTeam, setCurrentTeam] = useState("")
    const [finalFightShowQuestion, setFinalFightShowQuestion] = useState(false)
    const [finalFightShowAnswer, setFinalFightShowAnswer] = useState(false)

    const round_names = ["Round One", "Round Two", "Round Three"]

    useEffect(() => {
        getGame()
        //showPlayers()
        return;
        setModalContent(
            <InstructionsModal
                modalButtonFunc={showPlayers}
                logo={"/images/verse/verse-logo.png"}
                darkLogo={"/images/verse/verse-logo-dark.png"}
                mode={mode}
                content={
                    <div className='black_text instructions_content'>
                        <div className='section flex-column'>
                            <p className='words black_text'>Tops, pull your fists out of your bottoms and get ready to fight!</p>
                        </div>
                        <div className='section flex-column'>
                            <p className='header'>Setting Up:</p>
                            <p className='words'>It's the ultimate gay mental showdown! Split up into two teams with the tops and bottoms on each side. Then fill out your rosters for each team while the game master pulls up the answers on their phone.</p>
                        </div>
                        <div className='section flex-column'>
                            <p className='header'>How To Play:</p>
                            <p className='words'>The game is split up into 3 Fight Rounds and then the Final Fight! The goal of each question is to guess the most popular answers.</p>
                        </div>
                        <div className='section flex-column'>
                            <p className='header'>Fight Rounds</p>
                            <p className='words'>Each fight consists of three questions. First the game master will draft one randomly chosen player from each team. Then he will reveal a question on the screen and read it aloud. The board will show 4-8 popular answers for the question. The first of these two players to ring the bell or raise their hand will get a chance to answer the question.</p>
                            <p className='words'>If the player guesses the most popular answer their team will get a chance to choose if they want to <b>Punch</b> or <b>Dodge</b>. If not the other team has a chance to guess, and if they guess a higher answer they get to choose if their team will <b>Punch</b> or <b>Dodge</b></p>
                            <p className='words'><i>Punch</i> means your team will now rotate through taking turns guessing the rest of the answers on the board. <b>You are not allowed to talk to each other during this part.</b>
                                Just like in baseball you get three strikes. Each time your team misses a question, the other team will 'Dodge' and they will get 10 points. If you get all of the answers on the board then your team gets all of the points. If you hit all three strikes the other team gets a chance to <b>Parry</b></p>
                            <p className='words'>When the opposing team gets to <b>parry</b> they get one chance to guess one of the remaining answers on the board. If they are correct they will win all of the uncovered points on the board, minus ten points for every answer that is still covered.</p>
                            <p className='words'><i>Dodge</i> means the other team gets to punch and your team will wait for a chance to <b>parry</b>.</p>
                            <p className='words'>The game master can click on each answer to reveal it or press the corresponding number 1 - 8 to uncover it. X can be pressed to indicate a missed answer, and the other buttons are on the screen</p>
                            <p className='words'>At the end of the third question a winner will be declared for that round.</p>
                        </div>
                        <div className='section flex-column'>
                            <p className='header'>Final Fight!</p>
                            <p className='words'>A winner will have been chosen for each of the three previous rounds. Whichever team won the most fights will enter the final fight as the champion and the other team will be the challenger</p>
                            <p className='words'>The final fight consists of 5 head to head questions listed one by one. Each team will select an MVP to answer on behalf of the team. The game master will read each question and both players will try to guess the most popular answer. For the 1st, 3rd, and 5th questions the champion will answer first, and the challenger second. Answers cannot be duplicated. For the 2nd and 4th questions the challenger will get to answer first. If one team wins all three fight rounds then they get to answer first on all of the questions.</p>
                            <p className='words'>After each question the game master will reveal the possible answers, and the players are given their points accordingly. After the final question the team with the most points in the final fight is the winner!</p>
                        </div>
                    </div>
                } />
        )
        setShowModal(true)
    }, []);

    const navigate = useNavigate();

    const getGame = () => {
        let url = getBackendUrl() + '/api/verse/' + getGameID()
        fetch(url)
            .then(response => response.json())
            .then((jsonData) => {
                // jsonData is parsed json object received from url
                console.log(jsonData)
                processGrid(jsonData)
                setGameLoaded(true);

            })
            .catch((error) => {
                // handle your errors here
                console.error(error)
            })
    }

    const processGrid = (grids_array) => {
        let first_round = [];
        let second_round = [];
        let third_round = [];
        let fourth_round = [];

        let shuffled = shuffleArray(grids_array)

        shuffled.forEach((element, index) => {
            let new_obj = {
                question: element.question_text,
                answers: [],
                totalPoints: 0,
                winner: "",
            }

            let points_array = getAnswerPoints(element.num_of_answers);
            let tP = 0;

            for (let i = 1; i <= 8; i++) {
                tP += points_array[i - 1];
                new_obj.answers.push(
                    {
                        text: element['answer_' + i],
                        points: points_array[i - 1],
                    }
                )
            }

            new_obj.totalPoints = tP

            if (index < 3) {
                first_round.push(new_obj);
            } else if (index < 6) {
                second_round.push(new_obj);
            } else if (index < 9) {
                third_round.push(new_obj);
            } else {
                fourth_round.push(new_obj);
            }

        });


        setGrids([first_round, second_round, third_round, fourth_round])
    }

    const getAnswerPoints = (total) => {
        let left_points = getRandomInt(50) + 100;
        let arr = []
        for (let i = 1; i <= 8; i++) {
            if (i < total) {
                let val = getRandomInt(Math.round(left_points));
                if (val > (50)) {
                    val = val - 10
                }

                left_points = left_points - val;

                if (val < 4) {
                    val = 2 + getRandomInt(10) + getRandomInt(10)
                }

                arr.push(val)
            } else if (i == total) {
                if (left_points > 7) {
                    arr.push(left_points)
                } else {
                    arr.push(getRandomInt(10) + 5)
                }

            } else {
                arr.push(0)
            }
        }
        let sorted = arr.sort((a, b) => a - b);
        sorted.reverse();
        return sorted
    }

    const adjustPoints = (points) => {
        if (outstandingPoints != 0 || missedPoints != 0) {
            let pointsLeft = outstandingPoints + points
            let missPoints = missedPoints - points

            setOutstandingPoints(pointsLeft)
            setMissedPoints(missPoints)
        }
    }

    const givePoints = (team) => {
        if (team == "top") {
            let points = topPoints + getPointTotal();
            console.log()
            setTopPoints(points)
        } else if (team == "bottom") {
            let points = bottomPoints + getPointTotal();
            setBottomPoints(points)
        }
        setOutstandingPoints(0)
        setMissedPoints(0)
        grids[currentGrid][currentQuestionIndex].winner = team
    }

    const getPointTotal = () => {
        let pts = outstandingPoints - missedPoints;
        if (pts < 0) {
            pts = 0;
        }
        return pts;
    }

    const changeRound = (direction) => {
        setCurrentTeam("")
        let i = currentGrid
        if (direction) {
            if (i < grids.length - 1) {
                i = i + 1
            }
            let copy = [...roundWinners]
            if (topPoints > bottomPoints) {
                copy[i - 1] = "top"
            } else if (topPoints != bottomPoints) {
                copy[i - 1] = "bottom"
            }
            setRoundWinners(copy)
            setTopPoints(10)
            setBottomPoints(10)
            setTotalPoints(20)
        } else {
            if (i > 0) {
                i = i - 1
            }
        }
        setCurrentGrid(i)
    }

    const changeFinalQuestion = (direction) => {
        setFinalFightShowAnswer(false)
        setFinalFightShowQuestion(false)
        let i = currentFinalQuestionIndex;
        if (direction) {
            i += 1;
        } else {
            i -= 1;
        }
        setCurrentFinalQuestionIndex(i)
    }

    const changeQuestion = (direction) => {
        setCurrentTeam("")
        setShowQuestion(false)
        let i = currentQuestionIndex;
        if (direction) {
            if (i >= 2) {
                i = 0;
                changeRound(direction)
            } else {
                i = i + 1
            }
        } else {
            if (i <= 0) {
                i = 2;
                changeRound(direction)
            } else {
                i = i - 1
            }
        }
        setCurrentQuestionIndex(i)
    }

    const showCurrentQuestion = () => {
        if (grids[currentGrid][currentQuestionIndex]) {
            grids[currentGrid][currentQuestionIndex].winner = ""
            setCurrentTeam("")
            setCurrentQuestion(grids[currentGrid][currentQuestionIndex])
            setShowQuestion(true)
            setOutstandingPoints(0)
            console.log(grids[currentGrid][currentQuestionIndex])
            setMissedPoints(grids[currentGrid][currentQuestionIndex].totalPoints)
        }
    }

    const showPlayers = () => {
        setShowModal(false)
        setModalContent(
            <PlayersModal
                modalButtonFunc={setupPlayers}
                logo={"/images/verse/verse-logo.png"}
                darkLogo={"/images/verse/verse-logo-dark.png"}
                mode={mode}
            />
        )
        setShowModal(true)
    }

    const getColor = (team) => {
        let input = team;
        if (!input) {
            input = currentTeam
        }
        if (team == "top") {
            return "blue"
        } else if (team == "bottom") {
            return "red"
        } else {
            return ""
        }
    }

    const finalFightPunch = (team) => {
        if (team == "top") {
            if (bottomLives > 1) {
                setBottomLives(bottomLives - 1)
            } else {
                setBottomLives(0);
                setWinner("top")
            }
        } else if (team == "bottom") {
            if (topLives > 1) {
                setTopLives(topLives - 1)
            } else {
                setTopLives(0);
                setWinner("bottom")
            }
        }
    }

    const setupPlayers = (arr) => {
        setShowModal(false)
        setTopPlayers(arr[0])
        setBottomPlayers(arr[1])
        setShowContent(true)
    }

    const wrongAnswer = () => {
        if (currentTeam == "bottom") {
            let pts = topPoints + 10;
            let tot_pts = totalPoints + 10;
            setTopPoints(pts);
            setTotalPoints(tot_pts)
        } else if (currentTeam == "top") {
            let pts = bottomPoints + 10;
            let tot_pts = totalPoints + 10;
            setBottomPoints(pts);
            setTotalPoints(tot_pts)
        }
    }

    return (
        <div className={"verse-page page " + mode + `${!showContent ? " hidden" : ""}`}>
            {winner !== "" && (
                <div className={`winner_modal flex-column ${mode}`}>
                    <img alt="background" className={""} src={`/images/verse/${winner}s-win.png`} />
                    <p className='clickable black_text' onClick={() => { setShowModal(false); navigate("/games") }}>Back To Games Gallery</p>
                </div>
            )}
            <img alt="background" className={"page-background-image"} src={"/images/verse/verse-back.jpg"} />
            <div alt="background" className="loadingImage" style={{ backgroundImage: `url(images/verse/answer__back.png)` }}></div>
            <div alt="background" className="loadingImage" style={{ backgroundImage: `url(images/verse/answer_blue_back.png)` }}></div>
            <div alt="background" className="loadingImage" style={{ backgroundImage: `url(images/verse/answer_red_back.png)` }}></div>
            <div className='flex-column relative'>
                <div className='hamburger-menu-container'>
                    <HamburgerMenu mode={mode} setMode={setMode} isGames={false} />
                </div>
                <div className={`fight_meter_container flex-row ${getColor()} ${mode}_border`}>
                    <div className='fight_meter_indicator blue'>TOPS</div>
                    <div className='fight_meter_bar flex-row'>
                        <div className='fight_meter_bar_segment blue flex-row' style={{ width: `${(topPoints / totalPoints) * 100}%` }}><p>{topPoints} pts</p></div>
                        <div className='fight_meter_bar_segment red flex-row' style={{ width: `${(bottomPoints / totalPoints) * 100}%` }}><p>{bottomPoints} pts</p></div>
                    </div>
                    <div className='fight_meter_indicator red'>BOTTOMS</div>
                </div>
                <div className='content_grids flex-row'>
                    <div className={`fight_log flex-column ${mode}_border`}>
                        <div className='black_text fight_log_title_container'>
                            <p className='title'>Fight Log:</p>
                            <p>Round {currentGrid + 1} Fight {currentQuestionIndex + 1}</p>
                        </div>
                        {grids.map((grid, index) =>
                            <Fragment key={'fight_record_' + index}>
                                {index < 3 && (
                                    <div className={`fight_record_card flex-column ${mode} ${getColor(roundWinners[index])}`}>
                                        <div className={`fight_record_card_info flex-column`}>
                                            <p className='fight_record_title'>{round_names[index]}</p>
                                            <p className={`fight_record_winner ${getColor(roundWinners[index]) != "" ? "" : "invisible"}`}>Winner: {roundWinners[index]}s</p>
                                        </div>
                                        <div className={`fight_record_card_rounds_container flex-row`}>
                                            {grid.map((fight, i) =>
                                                <div key={'fight_' + index + '_' + i} className='fight_card_image flex-column'>
                                                    <img src={`images/verse/controls/fight-${currentGrid == index && currentQuestionIndex == i ? "current" : fight.winner}.png`} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </Fragment>

                        )}
                    </div>
                    <div className='main_area flex-column'>
                        {fightMode == "regular" && (
                            <div className={`main_grid ${getColor()} ${!showQuestion ? "neutral" : ""}`}>
                                <div className={`flex-row ${mode}`}>
                                    <div className='arrow_div flex-column'>
                                        <img src="/images/verse/controls/left-arrow.png" className={currentQuestionIndex > 0 || currentGrid > 0 ? "clickable" : "hidden"} onClick={() => changeQuestion(false)} />
                                    </div>
                                    {showQuestion && (
                                        <div className='question_grid flex-column'>
                                            <div className='question_text flex-column' style={{ backgroundImage: `url(images/verse/answer_${getColor(currentTeam)}_back.png)` }}>
                                                <p>{currentQuestion.question}</p>
                                            </div>
                                            <div className='answer_grid flex-row'>
                                                <div className='answer_column flex-column'>
                                                    <AnswerBox i={1} current_question={currentQuestion} adjustPoints={adjustPoints} team={getColor(currentTeam)} />
                                                    <AnswerBox i={3} current_question={currentQuestion} adjustPoints={adjustPoints} team={getColor(currentTeam)} />
                                                    <AnswerBox i={5} current_question={currentQuestion} adjustPoints={adjustPoints} team={getColor(currentTeam)} />
                                                    <AnswerBox i={7} current_question={currentQuestion} adjustPoints={adjustPoints} team={getColor(currentTeam)} />
                                                </div>
                                                <div className='answer_column flex-column'>
                                                    <AnswerBox i={2} current_question={currentQuestion} adjustPoints={adjustPoints} team={getColor(currentTeam)} />
                                                    <AnswerBox i={4} current_question={currentQuestion} adjustPoints={adjustPoints} team={getColor(currentTeam)} />
                                                    <AnswerBox i={6} current_question={currentQuestion} adjustPoints={adjustPoints} team={getColor(currentTeam)} />
                                                    <AnswerBox i={8} current_question={currentQuestion} adjustPoints={adjustPoints} team={getColor(currentTeam)} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    }
                                    {!showQuestion && (
                                        <div className={"question_grid_cover flex-column"}>
                                            <img src="/images/verse/actions/fight.png" onClick={() => showCurrentQuestion()} />
                                        </div>
                                    )}
                                    <div className='arrow_div flex-column'>
                                        <img src="/images/verse/controls/right-arrow.png" className={currentQuestionIndex < 2 || currentGrid < 2 ? "clickable" : "hidden"} onClick={() => changeQuestion(true)} />
                                    </div>
                                </div>
                            </div>
                        )}
                        {fightMode == "final" && (
                            <div className='final_grid flex-column'>
                                <div className='flex-row'>
                                    <div className='hearts_container flex-row'>
                                        <Heart team={"top"} lives={topLives} index={1} />
                                        <Heart team={"top"} lives={topLives} index={2} />
                                        <Heart team={"top"} lives={topLives} index={3} />
                                    </div>
                                    <div className='hearts_spacer'></div>
                                    <div className='hearts_container bottom flex-row'>
                                        <Heart team={"bottom"} lives={bottomLives} index={1} />
                                        <Heart team={"bottom"} lives={bottomLives} index={2} />
                                        <Heart team={"bottom"} lives={bottomLives} index={3} />
                                    </div>
                                </div>
                                <div className='question_container flex-column'>
                                    <div className='question_text flex-column' style={{ backgroundImage: `url(images/verse/answer_${getColor(currentTeam)}_back.png)` }}>
                                        <div style={{ backgroundImage: `url(images/verse/answer__back.png)` }} onClick={() => { setFinalFightShowQuestion(true) }} className={`answer_cover black_text flex-row ${finalFightShowQuestion ? "hidden" : ""}`}>? ? ? ? ? </div>
                                        <div className={`question_text_container flex-row ${finalFightShowQuestion ? "" : "hidden"}`}>
                                            <p>{grids[3][currentFinalQuestionIndex].question}</p>
                                        </div>
                                    </div>
                                    <div className='answer_grid flex-row'>
                                        <div className='final_answer_column flex-column'>
                                            <FinalAnswerBox i={1} current_question={grids[3][currentFinalQuestionIndex]} team={""} finalFightShowAnswer={finalFightShowAnswer} setFinalFightShowAnswer={setFinalFightShowAnswer} />
                                            <FinalAnswerBox i={2} current_question={grids[3][currentFinalQuestionIndex]} team={""} finalFightShowAnswer={finalFightShowAnswer} setFinalFightShowAnswer={setFinalFightShowAnswer} />
                                            <FinalAnswerBox i={3} current_question={grids[3][currentFinalQuestionIndex]} team={""} finalFightShowAnswer={finalFightShowAnswer} setFinalFightShowAnswer={setFinalFightShowAnswer} />
                                            <FinalAnswerBox i={4} current_question={grids[3][currentFinalQuestionIndex]} team={""} finalFightShowAnswer={finalFightShowAnswer} setFinalFightShowAnswer={setFinalFightShowAnswer} />
                                            <FinalAnswerBox i={5} current_question={grids[3][currentFinalQuestionIndex]} team={""} finalFightShowAnswer={finalFightShowAnswer} setFinalFightShowAnswer={setFinalFightShowAnswer} />
                                        </div>
                                    </div>
                                </div>
                                <div className='flex-row punch_spacer'>
                                    <div className='arrow_div flex-column'>
                                        <img src="/images/verse/controls/left-arrow.png" className={currentFinalQuestionIndex > 0 ? "clickable" : "hidden"} onClick={() => changeFinalQuestion(false)} />
                                    </div>
                                    <img onClick={() => { finalFightPunch("top") }} className="final_fight_punch_button clickable" src="/images/verse/actions/punch-top.png" />
                                    <div className='punch_spacer'></div>
                                    <img onClick={() => { finalFightPunch("bottom") }} className="final_fight_punch_button clickable" src="/images/verse/actions/punch-bottom.png" />
                                    <div className='arrow_div flex-column'>
                                        <img src="/images/verse/controls/right-arrow.png" className={currentQuestionIndex < 4 ? "clickable" : "hidden"} onClick={() => changeFinalQuestion(true)} />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={`controls flex-column ${mode}_border`}>
                        <div className={`top_spacer flex-column black_text ${showQuestion ? "visible" : "invisible"}`}>
                            <p className='header'>Points</p>
                            <p>  Earned: {outstandingPoints}</p>
                            <p>-Missing: {missedPoints}</p>
                            <hr />
                            <p> Total: {getPointTotal()}</p>
                        </div>
                        {fightMode === "regular" && (
                            <div>
                                {currentTeam === "" && (
                                    <div className={`flex-column ${showQuestion ? "visible" : "invisible"} `}>
                                        <img onClick={() => setCurrentTeam("top")} src="/images/verse/actions/punch-top.png" className='clickable' />
                                        <img onClick={() => setCurrentTeam("bottom")} src="/images/verse/actions/dodge-top.png" className='clickable' />
                                        <hr />
                                        <img onClick={() => setCurrentTeam("bottom")} src="/images/verse/actions/punch-bottom.png" className='clickable' />
                                        <img onClick={() => setCurrentTeam("top")} src="/images/verse/actions/dodge-bottom.png" className='clickable' />
                                    </div>
                                )}
                                {currentTeam === "top" && (
                                    <div className='flex-column'>
                                        <img src="/images/verse/actions/ko-top.png" style={{ opacity: "0" }} />
                                        <img onClick={() => givePoints("top")} src="/images/verse/actions/ko-top.png" className='clickable' />
                                        <hr />
                                        <img onClick={() => wrongAnswer()} src="/images/verse/actions/dodge-bottom.png" className='clickable' />
                                        <img onClick={() => setCurrentTeam("bottom")} src="/images/verse/actions/parry-bottom.png" className='clickable' />
                                    </div>
                                )}
                                {currentTeam === "bottom" && (
                                    <div className='flex-column'>
                                        <img onClick={() => wrongAnswer()} src="/images/verse/actions/dodge-top.png" className='clickable' />
                                        <img onClick={() => setCurrentTeam("top")} src="/images/verse/actions/parry-top.png" className='clickable' />
                                        <hr />
                                        <img onClick={() => givePoints("bottom")} src="/images/verse/actions/ko-bottom.png" className='clickable' />
                                        <img src="/images/verse/actions/ko-top.png" style={{ opacity: "0" }} />
                                    </div>
                                )}
                            </div>)}

                        <div className='bottom_spacer'>
                            {fightMode === "regular" && (
                                <div className='flex-column'>
                                    <hr />
                                    <img onClick={() => setFightMode("final")} src="/images/verse/actions/final-fight.png" className='clickable' />
                                </div>
                            )}
                            {fightMode === "final" && (
                                <div className='flex-column'>
                                    <hr />
                                    <img onClick={() => setFightMode("regular")} src="/images/verse/actions/normal-fight.png" className='clickable' />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

const Heart = ({ team, lives, index }) => {
    return (
        <div className={`heart flex-column ${index <= lives ? "" : "hidden"}`}>
            <img className='heart-frame' src={`images/verse/controls/heart-${team}-frame.png`} />
            <img className='heart-center' src={`images/verse/controls/heart-${team}-center.png`} />
        </div>
    )
}

const FinalAnswerBox = ({ i, current_question, finalFightShowAnswer, setFinalFightShowAnswer }) => {
    let answer = current_question.answers[i - 1]

    return (
        <div className={`answer_container final_answer_container flex-column`} style={{ backgroundImage: `url(images/verse/answer__back.png)` }}>
            {answer.text != "" && (
                <div className='has_answer_container flex-column' >
                    <div style={{ backgroundImage: `url(images/verse/answer__back.png)` }} onClick={() => { setFinalFightShowAnswer(true) }} className={`final_answer_cover black_text flex-row ${finalFightShowAnswer ? "hidden" : ""}`}>{i}</div>
                    <div className={`answer_info flex-row`}>
                        <div className='answer_text flex-row black_text'>
                            {answer.text}
                        </div>
                    </div>
                </div>
            )}
            {answer.text == "" && (
                <div className='no_answer_container final_no_answer_container flex-row'>

                </div>
            )}
        </div>
    )

}

const AnswerBox = ({ i, current_question, team, adjustPoints }) => {
    const [revealedAnswers, setRevealedAnswers] = useState([])
    let answer = current_question.answers[i - 1]

    const revealAnswer = (i, pts) => {
        let copy = [...revealedAnswers]
        copy.push(i)
        setRevealedAnswers(copy)
        adjustPoints(pts)
    }

    return (
        <div className={`answer_container flex-column ${team}`} style={{ backgroundImage: `url(images/verse/answer_${team}_back.png)` }}>
            {answer.text != "" && (
                <div className='has_answer_container flex-column' >
                    <div style={{ backgroundImage: `url(images/verse/answer_${team}_back.png)` }} onClick={() => { revealAnswer(i, answer.points) }} className={`answer_cover black_text flex-row ${revealedAnswers.includes(i) ? "hidden" : ""}`}>{i}</div>
                    <div className={`answer_info flex-row ${revealedAnswers.includes(i) ? "" : "hidden"}`}>
                        <div className='answer_text flex-row black_text'>
                            {answer.text}
                        </div>
                        <div className='answer_points black_text'>
                            {answer.points}
                        </div>
                    </div>
                </div>
            )}
            {answer.text == "" && (
                <div className='no_answer_container flex-row'>

                </div>
            )}
        </div>
    )

}

export default Verse;