import React, {useEffect} from 'react';
import './logout.scss';
import { removeToken, setToken } from '../../components/Auth/useAuth';

const Logout = () => {


    useEffect(() => {
        handleLogout("redirect")
    });

    const handleLogout = () => {
        removeToken()
        // var headers = new Headers();
        // headers.append('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')

        // var formBody = buildForm({"jwt": auth?.jwt})

        // return fetch(`${getBaseUrl()}/saml/logout`, {
        //     method: 'post',
        //     credentials: 'include',
        //     mode: 'cors',
        //     headers: headers,
        //     body: formBody,
        // })
        // .then((res) => {
        //     window.location.reload(false);
        // })
        // .catch((error) => {
        //     console.log('error')
        //     console.error(error)
        // });
    }
};

export default Logout;