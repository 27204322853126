import React, {useState, useEffect} from 'react';
import {getToken, setToken, checkToken} from '../../components/Auth/useAuth';
import { setDisplayMode } from '../../components/GlobalFunctions/globalFunctions';

import './login.scss';

const Login = ({mode, setMode}) => {

    const [tokenChecked,setTokenChecked] = useState(false)
    const [validLogin,setValidLogin] = useState(false)
    const [username,setUsername] = useState("")
    const [password,setPassword] = useState("")

    useEffect(() => {
       checkTokenValid()
    });

function checkTokenValid(){
    if(checkToken()){
       setValidLogin(true)
    }else{
        setTokenChecked(true)
        console.log("TOKEN CHJECKED")
    }

}

const handleUChange = (event) => {
    const value = event.target.value;
    setUsername(value);
  };

  const handlePChange = (event) => {
    const value = event.target.value;
    setPassword(value);
  };

function handleLogin(event){
    event.preventDefault();
    console.log("HANDLING LOGIN")
    if(username === "" || password === ""){
        invalidLogin()
    }else if (username === "test" || password === "test"){
        setToken("NEW TOKEN")
        window.location = "/"
    }else{
        invalidLogin()
    }
}

function invalidLogin(){
    document.getElementById("submit-button").style.backgroundColor = "red"
        setTimeout(() => {
            document.getElementById("submit-button").style.backgroundColor = ""
        },500)
}

  return (
    <div className={"login-page " + mode}>
<hr></hr>
        <div className='presents_container black_text flex-column'>
            <p className="black_text presents_text">From the deepest depths of naughty humor, The Chaotic Top is proud to bring you:</p>
            <img alt="presents_logo" className="presents_image" src={"/images/logos/rainbow_logo.png"} alt="logo" />

        </div>
        <hr></hr>
        {tokenChecked && (
            <div className='flex-column form_container'>
              <p className="black_text presents_text tagline"><span>We're hosting!</span><br></br> login to cum and play</p>
                <div className="login-form">
                <hr></hr>
                <form className="flex-column login-form" onSubmit={handleLogin}>
                    <label for="games-username" className="black_text">Username:</label>
                    <input id="games-username" type="text" autoComplete="games-username" value={username} onChange={handleUChange}/>
                    <label for="games-password"className="black_text">Password:</label>
                    <input id="games-password" type="password" autoComplete="games-password" value={password} onChange={handlePChange} />
                    <button id="submit-button" className='standard-button white_text' type="submit">Login</button>
                </form>
            </div>
            </div>
        )}

        <div className='settings_container flex-row'>
            {mode === "dark_mode" && (
                <p className="black_text mode_text" onClick={() => {setDisplayMode("light_mode");setMode("light_mode")}}>Switch to light mode</p>
            )}
            {mode !==  "dark_mode" && (
                <p className="black_text mode_text" onClick={() => {setDisplayMode("dark_mode");setMode("dark_mode")}}>Switch to dark mode</p>
            )}
        </div>
    </div>
  )
};

export default Login;