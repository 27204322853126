
import React, { useState, useEffect } from 'react';
import './App.scss';
import { Routes, Route, Navigate } from 'react-router-dom';
import {getToken, setToken} from './components/Auth/useAuth';
import Games from './pages/games/games';
import Login from './pages/login/login';
import Logout from './pages/logout/logout';
import Dare from './pages/playgame/dare/dare';
import Grind from './pages/playgame/grind/grind';
import Verse from './pages/playgame/verse/verse';
import { getDisplayMode } from './components/GlobalFunctions/globalFunctions';

function App() {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState();
  const [jwt, setJwt] = useState();
  const [mode, setMode] = useState();

  useEffect(() =>{
    setToken("FAKE_TOKEN")
    let token = getToken()
    console.log("TOKEN: " + token)
    console.log(jwt !== "" && jwt !== null)
    console.log(jwt === "" || jwt === null)
    setJwt(token);
    setMode(getDisplayMode())
  },[])

  return (
    <>
        { jwt !== "" && jwt !== null && (
              <div className={"layout-container " + mode}>
                <div className="no-mobile flex-column">
                  <img className="presents_image" src={"/images/logos/rainbow_logo.png"} alt="logo" />
                  <p className="black_text title">This site will display best on a computer!</p>
                  <p className="black_text">If you're looking to join a game <a className="link clickable">Click Here!</a></p>
                  <p className="black_text">If you're the game master looking for the answers <a className="link clickable">Click Here!</a></p>
                </div>
                <div className={`flex-column modal-content ${showModal ? "visible" : "hidden"}`}>{modalContent}</div>
                  <Routes>
                    <Route exact path='/playgame/dare' element={<Dare showModal={showModal} jwt={jwt} mode={mode} setMode={setMode} setShowModal={setShowModal} modalContent={modalContent} setModalContent={setModalContent} />} />
                    <Route exact path='/playgame/grind' element={<Grind showModal={showModal} jwt={jwt} mode={mode} setMode={setMode} setShowModal={setShowModal} modalContent={modalContent} setModalContent={setModalContent} />} />
                    <Route exact path='/playgame/verse' element={<Verse showModal={showModal} jwt={jwt} mode={mode} setMode={setMode} setShowModal={setShowModal} modalContent={modalContent} setModalContent={setModalContent} />} />
                    <Route exact path='/logout' element={<Logout showModal={showModal} jwt={jwt}setShowModal={setShowModal} modalContent={modalContent} setModalContent={setModalContent}  />} />
                    <Route path='/' element={<Games showModal={showModal} jwt={jwt} mode={mode} setMode={setMode} setShowModal={setShowModal} modalContent={modalContent} setModalContent={setModalContent} />} />
                    <Route path='/games' element={<Games showModal={showModal} jwt={jwt} mode={mode} setMode={setMode} setShowModal={setShowModal} modalContent={modalContent} setModalContent={setModalContent} />} />
                    <Route path='*' element={<Games showModal={showModal} jwt={jwt} mode={mode} setMode={setMode} setShowModal={setShowModal} modalContent={modalContent} setModalContent={setModalContent} />} />
                  </Routes>`
              </div>
          )  
        }
        { jwt === "" || jwt === null && (<div className="layout-container">
                  <Routes>
                    <Route path='/' element={<Login showModal={showModal} jwt={jwt} mode={mode} setMode={setMode} setShowModal={setShowModal} modalContent={modalContent} setModalContent={setModalContent} />} />
                    <Route path="*" element={<Navigate to ="/" />}/>
                  </Routes>
            </div>
          )
        }
    </>
  );
}


export default App;